import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingController, ModalController } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { HelpersService } from 'src/app/services/helpers.service';
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.page.html",
  styleUrls: ["./notification.page.scss"],
})
export class NotificationPage implements OnInit {
  @Input("payload") payload;
  condominio: any = {};
  platform = '';
  constructor(
    private helperService: HelpersService,
    private loadingController: LoadingController,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private modalController: ModalController
  ) {}
  title: String = "";
  msg: String = "";
  btn1: String = "";
  btn2: String = "";
  image: String = "";
  icon: String = "";
  route: String = "";
  loader;
  ngOnInit() {
    this.platform = this.helperService.getDeviceType();
    this.condominio = this.storageService.get("usuario_condominio").condominio;
    this.setupData();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  getTitle(){
    if(this.payload.gcm && this.payload.gcm.title){
      return this.payload.gcm.title
    } else if(this.payload.aps && this.payload.aps.alert && this.payload.aps.alert.title){
      return this.payload.aps.alert.title
    } else {
      return this.payload.title;
    }
  }

  getBody(){
    if(this.payload.gcm && this.payload.gcm.body){
      return this.payload.gcm.body
    } else if(this.payload.aps && this.payload.aps.body && this.payload.aps.alert.body){
      return this.payload.aps.alert.body
    } else {
      return this.payload.message;
    }
  }
  
  setupData() {
    const typeSkipping = [30];

    if (!this.payload || typeSkipping.includes(parseInt(this.payload.tipo))){
      setTimeout(() => {
        this.modalController.dismiss();
      }, 500);
      return;
    };
    if (
      parseInt(this.payload.condominio) === 
      parseInt(this.condominio.id_condominio)  || 
      this.payload.condominio === 'todos'
    ) {
      this.title = this.getTitle();
      this.msg = this.getBody();
      this.btn2 = "Fechar";
    } else {
      this.title = "Notificação de outro condomínio.";
      this.msg = "Deseja trocar de condomínio?";
      this.btn1 = "Trocar";
      this.btn2 = "Agora não";
    }

    this.image = this.payload.image ? this.payload.image : "assets/img/loading.png";

    if(parseInt(this.payload.tipo) === 7){
      this.image = this.payload.image ? this.payload.image : "assets/img/avatarDefault.png";
    }

  }

  redirect() {
    if (
      parseInt(this.payload.condominio) !==
      parseInt(this.condominio.id_condominio)
    ) {
      let usuarios_condominio = this.storageService.get("usuarios_condominio");
      usuarios_condominio.map((item) => {
        if(parseInt(item.id_condominio) === parseInt(this.payload.condominio) && item.tipo_usuario === 'MORADOR'){
          this.dismiss();
          this.changeCondominio(
            item.id_condominio,
            item.id_usuario_condominio,
            item
          );
        }
      });
    } else if (parseInt(this.payload.tipo) === 4) {
      this.router.navigate(["chat"]);
      this.dismiss();
    }
  }

  async changeCondominio(
    id_condominio,
    id_usuario_condominio,
    usuario_condominio
  ) {
    this.loader = await this.loadingController.create({
      message: "Trocando de condomínio...",
    });

    this.authService
      .updateToken(id_condominio, id_usuario_condominio)
      .then((response) => {
        this.storageService.set("usuario_condominio", usuario_condominio);
        this.router.navigate(["/home-loader"], { replaceUrl: true });

        setTimeout(() => {
          this.loader.dismiss();
          this.authService.reloadMenuPermissions();
        }, 300);
      })
      .catch((err) => {
        
        this.loader.dismiss();
      });
  }
}
