<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Editar contato</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item>
    <ion-label>Tipo</ion-label>
    <ion-select
      (ionChange)="tipoTxt()"
      [selectedText]="tipo_txt"
      [(ngModel)]="contato.tipo.id"
      okText="Selecionar"
      cancelText="Cancelar"
    >
      <ion-select-option [value]="tipo.id" *ngFor="let tipo of tipos"
        >{{ tipo.descricao }}</ion-select-option
      >
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Contato</ion-label>
    <ion-input
      name="contato"
      type="text"
      [(ngModel)]="contato.contato"
      required
    ></ion-input>
  </ion-item>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="success" (click)="update()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
