import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from './storage.service';
import settings from "whitelabel/ts/config";
@Injectable({
    providedIn: 'root'
})
export class CustomizationService {

    constructor(
        private storageService: StorageService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    menuPermissions(permissions, menu) {
        if (permissions.config_comunicados >= 1) menu = this.changeMenuItem(menu, 'comunicados-adm');

        if (permissions.config_moradores >= 1) menu = this.changeMenuItem(menu, 'moradores-adm');

        if (permissions.config_funcionarios >= 1) menu = this.changeMenuItem(menu, 'funcionarios-adm');

        if (permissions.auditoria >= 1) menu = this.changeMenuItem(menu, 'auditoria');

        if (permissions.config_documentos >= 1) menu = this.changeMenuItem(menu, 'documentos-adm');

        if (permissions.config_mudanca >= 1) menu = this.changeMenuItem(menu, 'mudancas-adm');

        if (permissions.config_unidades >= 1) menu = this.changeMenuItem(menu, 'unidades-adm');

        if (permissions.config_visita_motivo >= 1) menu = this.changeMenuItem(menu, 'motivos-de-visita');

        if (permissions.config_reservas >= 1) menu = this.changeMenuItem(menu, 'areas-comuns-adm');

        if (permissions.config_ocorrencia >= 1) menu = this.changeMenuItem(menu, 'ocorrencias-adm');

        if (permissions.config_enquete >= 1) menu = this.changeMenuItem(menu, 'enquetes-adm');

        if (permissions.config_pet >= 1) {
            menu = this.changeMenuItem(menu, 'pets-adm');
            menu = this.changeMenuItem(menu, 'especies-adm');
        }

        // if (permissions.config_agenda_tarefa >= 1) menu = this.changeMenuItem(menu, 'agenda-adm');

        if (permissions.config_fornecedores >= 1) menu = this.changeMenuItem(menu, 'fornecedores-adm');

        // if (permissions.config_contratos >= 1) menu = this.changeMenuItem(menu, 'contratos-adm');

        if (permissions.config_grupos >= 1) menu = this.changeMenuItem(menu, 'grupos-adm');

        // if (permissions.config_patrimonio >= 1) menu = this.changeMenuItem(menu, 'patrimonio-adm');

        if (permissions.config_correspondencia_adm >= 1) menu = this.changeMenuItem(menu, 'correspondencia-adm');

        if (permissions.config_assembleias_adm >= 1) menu = this.changeMenuItem(menu, 'assembleias-adm');

        if (permissions.config_mudanca >= 1) menu = this.changeMenuItem(menu, 'mudancas-adm');

        if (permissions.config_atendimento >= 1) menu = this.changeMenuItem(menu, 'atendimento-adm');

        if (permissions.config_saida_materais >= 1) menu = this.changeMenuItem(menu, 'saida-materiais-adm');

        if (permissions.config_mobile_device >= 1) menu = this.changeMenuItem(menu, 'leitoresmobile-adm');

        if (permissions.config_tabela_horarios >= 1) menu = this.changeMenuItem(menu, 'tabela-horarios-adm');

        return menu;
    }
    changePageStyleByCategory(){
        //root_categories
        let categories = this.storageService.get('root_categories');
        console.log(categories)
        this.route.queryParams
        .subscribe(params => {
          let id = params.categoria;
          let categoria = categories[categories.findIndex(e => e.id_categoria == id)];
          let usuario_condominio = this.storageService.get("usuario_condominio");
          if (usuario_condominio.tipo_usuario === "MORADOR" && settings.custom_theme == true){
            let body = document.getElementsByTagName('body')[0];
            if (body && categoria.cor) {
              body.style.setProperty('--ion-color-primary', categoria.cor)
              body.style.setProperty('--ion-toolbar-background', categoria.cor)
            }else{
              body.style.setProperty('--ion-color-primary', settings.main_color)
              body.style.setProperty('--ion-toolbar-background', settings.main_color)
            }
          }
        }
      );
    }
    menuPermissionsMorador(paginas, menu) {

        let indexMenuHelp = menu.findIndex(e => e.id == 'ajuda')
        let indexMenuHome = menu.findIndex(e => e.id == "home")
        let menuHelp = menu[indexMenuHelp];
        let menuHome = menu[indexMenuHome];
        menu = [];
        menu.push(menuHome);
        this.storageService.set("paginas_customizadas", paginas);
        menu[menu.findIndex(e => e.id == 'ajuda')]
        for (let i = 0; i < paginas.length; i++) {
            const element = paginas[i];
            console.log("PAGINA: "+element['pagina'].show_in_menu)
            menu = this.changeMenuItem(menu, {
                enabled: element['pagina'].show_in_menu,
                icon: element['pagina'].icon,
                id: element['pagina'].id,
                notificacoes: undefined,
                link_externo: element['pagina'].link_externo,
                title: element['pagina_nome'],
                url: element['pagina'].url,
                cor: element.cor,
            });
        }

        menu.push(menuHelp);

        return menu;
    }
    changeCurrentPageStyle(){
        let pages = this.storageService.get("customization_pages");
        let url   = this.router.url.split('?');
        if(pages.length){
          let usuario_condominio = this.storageService.get("usuario_condominio");
          let page = pages[pages.findIndex(e => e.url == url[0])];
          if (usuario_condominio.tipo_usuario === "MORADOR" && settings.custom_theme == true){
            let body = document.getElementsByTagName('body')[0];
            if (body && page.cor) {
              body.style.setProperty('--ion-color-primary', page.cor)
            }else{
              body.style.setProperty('--ion-color-primary', settings.main_color)
            }
          }
          return page;
        }
        return {'title': ''};
    }
    changeMenuItem(menu, key, enabled = true) {
        const elementsIndex = {
            enabled: key.enabled,
            icon: key.icon,
            id: key.id,
            notificacoes: undefined,
            title: key.title,
            url: key.url,
            cor: key.cor,
            link_externo: key.link_externo
        };

        menu.push(elementsIndex)
        return menu;
    }

    protectAdminPage(page) {
        return true;
    }

    handleMasterPage() {
        const usuario_condominio = this.storageService.get('usuario_condominio');
        if (usuario_condominio.tipo_usuario !== 'MASTER') {
            localStorage.clear();
            window.location.reload();
        }
    }
}
