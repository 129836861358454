<div  class="input-images ion-padding">
  <div class="avatarBox" style="background-image: url({{ preview }})">

  </div>

  <ion-button color="{{ color }}" (click)="choosePictures()" [hidden]="formdata">
    {{ title }}
  </ion-button>

  <ion-button color="{{ color }}" (click)="choosePictures()" [hidden]="!formdata">
    Trocar foto
  </ion-button>

  <ion-button color="secondary" (click)="sendEvent()" *ngIf="showEnviar">
    Enviar foto
  </ion-button>
</div>

<input type="file" id="{{ idInputFile }}" (change)="browser($event)" style="display: none;" />