export class Veiculo {
  id: Number = 0;
  id_condominio: String = "";
  modelo: any = {
    marca: {
      id: ""
    }
  };
  cor: any = {};
  placa: String = "";
  morador: Number = 0;
  foto: any = {
    foto: ""
  };
  acionador_lpr: {
    id: ""
  }
}
