import { Pipe, PipeTransform } from '@angular/core';
import {cpfPattern} from "../common/constants";
import vanillaMasker from 'vanilla-masker';

@Pipe({
  name: 'cpfMask'
})
export class CpfMaskPipe implements PipeTransform {

  transform(value: any) {

    if (!value) {
      return '';
    }

    return vanillaMasker.toPattern(value, cpfPattern);
  }

}
