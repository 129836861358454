import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';

@Injectable({
	providedIn: 'root',
})

export class SettingsService {
	constructor(private apiRequest: ApiRequestService) { }

	public list = async (params: any = {}) => {
		return this.apiRequest
			.apiServer('oauth/settings', params)
			.then((response) => {
				return response.data;
			});
	};

	public save = async (params: any = {}) => {
		return this.apiRequest
			.apiServer('admin/settings', params, 'POST')
			.then((response) => {
				return response.data;
			});
	};

	public delete = async (slug: any = {}) => {
		return this.apiRequest
			.apiServer('admin/settings/' + slug + '/delete', {}, 'POST')
			.then((response) => {
				return response.data;
			});
	};
}
