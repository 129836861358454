import { Component, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { MinhacontaService } from "src/app/services/minhaconta.service";
import { StorageService } from "src/app/services/storage.service";
import { FamiliarCreatePage } from "../familiar-create/familiar-create.page";
import { FamiliarEditPage } from "../familiar-edit/familiar-edit.page";
import { MeuPerfilEditarPage } from "../meu-perfil-editar/meu-perfil-editar.page";
import { MeusContatosPage } from "../meus-contatos/meus-contatos.page";
import { MeusVeiculosPage } from "../meus-veiculos/meus-veiculos.page";
import { NotificacoesConfigPage } from "../notificacoes/notificacoes-config/notificacoes-config.page";
import { MoradorFacialPage } from "../../admin/morador/morador-facial/morador-facial.page";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-meu-perfil",
  templateUrl: "./meu-perfil.page.html",
  styleUrls: ["./meu-perfil.page.scss"],
})
export class MeuPerfilPage implements OnInit {
  foto: string = "assets/img/avatarDefault.png";
  params = {
    foto: "",
  };
  loader;
  familiaresSkeleton: any = new Array<any>(10);
  familiares: any = [];
  perfil: any = {};
  familiaresLoaded: Boolean = false;
  canAddFamiliar: Boolean = false;
  canAddVeiculos: Boolean = false;
  condominio: any = {};
  facial_enabled: boolean = false;
  editFoto: Boolean = false;
  moradorFacial: Boolean = false;
  permissoes: any = {};
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private storageService: StorageService,
    private minhacontaService: MinhacontaService,
    private modalController: ModalController,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.condominio = this.storageService.get("usuario_condominio").condominio;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async ionViewWillEnter() {
    await this.loadPermissions();
    await this.setup();
    this.facial_enabled = this.condominio.facial_controlcondo === 1;
  }

  async modalMoradorFacial(morador) {
    console.log(morador)
    const modal = await this.modalController.create({
      component: MoradorFacialPage,
      componentProps: { morador }
    });
    return await modal.present();
  }

  async modalMoradorRemoverConta() {
    const alert = await this.alertController.create({
      header: "Excluir conta",
      message: 'Qual acesso você deseja excluir?',
      buttons: [
        {
          text: 'Cancelar',
          handler: null,
        },
        {
          text: 'Excluir este usuário',
          handler: async () => await this.confirmarExclusao().then(async response => {
            if (response) {
                await this.minhacontaService.delete().then(async response => {
                  this.dismiss();
                  await this.authService.logout();
                }).catch((err) => { });
            }
          }),
        },
        {
          text: 'Excluir todos os meus usuários',
          handler: async () => await this.confirmarExclusao().then(async response => {
            if (response) {
                await this.minhacontaService.delete({type:'many'}).then(async response => {
                  this.dismiss();
                  await this.authService.logout();
              }).catch((err) => { });
            }
          }),
        }
      ],
    });
      alert.present();
    }

    async confirmarExclusao() {
      return new Promise<boolean>(async resolve => {
        const alert = await this.alertController.create({
          header: "Confirmar exclusão",
          message: 'Tem certeza que deseja excluir sua conta?',
          buttons: [
            {
              text: 'Não',
              handler: () => resolve(false),
            },
            {
              text: 'Sim',
              handler: () => resolve(true),
            }
          ],
        });
        alert.present();
      });
    }
    

  async setup() {
    await this.loadMinhaConta();

  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {

        const { data } = response;
        this.permissoes = data

      })
      .catch((err) => { });
  }

  async loadMinhaConta() {
    await this.minhacontaService
      .get()
      .then((data) => {
        this.perfil = data;

        this.verificaPermissoes()

        if (data.foto && data.foto.foto) {
          this.foto = data.foto.foto;
        }

        if (this.perfil.parentesco && this.perfil.parentesco.id === 1) {
          this.loadFamiliares();
        } else {
          this.familiaresLoaded = true;
        }

      })
      .catch((err) => { });
  }

  verificaPermissoes() {

    this.editFoto = this.permissoes.morador_foto_perfil === 1 ? true : false;
    this.moradorFacial = this.permissoes.morador_facial === 1 ? true : false;

    this.canAddFamiliar = false

    this.canAddFamiliar = this.condominio.cadastra_familiar
    && this.perfil.parentesco
    && this.perfil.parentesco.id == 1
    && this.permissoes
    && this.permissoes.morador_cadastra_familiar == 1
    ? true : false;

    this.canAddVeiculos = false;

    this.canAddVeiculos = this.permissoes.morador_cadastra_lpr
    && this.permissoes.morador_cadastra_lpr == 1 ? true : false
  }

  async loadFamiliares() {
    await this.minhacontaService
      .moradores()
      .then((data) => {
        this.familiares = data;
      })
      .catch((err) => { });

    this.familiaresLoaded = true;
  }

  async responseAvatar(picture) {
    this.params.foto = picture.base64;
    this.foto = picture.base64;
    this.loader = await this.loadingController.create({
      message: "Atualizando foto do perfil...",
    });
    await this.loader.present();
    await this.minhacontaService
      .updateFoto({ foto: this.foto })
      .then((data) => { })
      .catch((err) => { });
    this.loader.dismiss();
  }

  async modalMeusContato() {
    const modal = await this.modalController.create({
      component: MeusContatosPage,
    });
    await modal.present();
  }

  async modalMeusVeiculos() {
    const modal = await this.modalController.create({
      component: MeusVeiculosPage,
    });
    await modal.present();
  }

  async modalMeuPerfilEdit() {
    const modal = await this.modalController.create({
      component: MeuPerfilEditarPage,
      componentProps: { morador: this.perfil },
    });
    await modal.present();
    modal.onWillDismiss().then(async (response) => {
      if (response.data && response.data.status) {
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Perfil atualizado.",
          buttons: ["OK"],
        });
        alert.present();
        await this.loadMinhaConta();
      }
    });
  }

  async modalFamiliarCreate() {
    const modal = await this.modalController.create({
      component: FamiliarCreatePage,
    });
    await modal.present();
    modal.onWillDismiss().then(async (response) => {
      if (response.data && response.data.status) {
        await this.loadFamiliares();
      }
    });
  }

  async modalFamiliarEdit(morador) {
    if (this.canAddFamiliar) {
      const modal = await this.modalController.create({
        component: FamiliarEditPage,
        componentProps: { morador },
      });
      await modal.present();
      modal.onWillDismiss().then(async (response) => {
        if (response.data && response.data.status) {
          await this.loadFamiliares();
        }
      });
    }
  }

  getFoto(autor) {
    if (autor && autor.foto && autor.foto.foto) {
      return autor.foto.foto;
    } else {
      return "assets/img/avatarDefault.png";
    }
  }

  async modalNotificacoes() {
    const modal = await this.modalController.create({
      component: NotificacoesConfigPage,
    });
    await modal.present();
  }
}
