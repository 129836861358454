import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ContatoEditPage } from 'src/app/modals/contato/contato-edit/contato-edit.page';
import { ContatosService } from 'src/app/services/contatos.service';
import { MinhacontaService } from 'src/app/services/minhaconta.service';
import { MeusContatosCreatePage } from '../meus-contatos-create/meus-contatos-create.page';
import { MeusContatosEditPage } from '../meus-contatos-edit/meus-contatos-edit.page';

@Component({
  selector: 'app-meus-contatos',
  templateUrl: './meus-contatos.page.html',
  styleUrls: ['./meus-contatos.page.scss'],
})
export class MeusContatosPage implements OnInit {
  contatos : any = [];
  contatosSkeleton : any = new Array<any>(10);
  canLoadMore: Boolean = true;
  contatosLoaded : Boolean = false;
  firstLoad : Boolean = true;
  params: any = {
    offset: 0, limit: 10
  };
  editContatos: Boolean = false;
  constructor(
    private minhacontaService: MinhacontaService,
    private contatoService: ContatosService,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {
        const { data } = response;
        this.editContatos = data.morador_contatos === 1 ? true : false;
      })
      .catch((err) => {});
  }

  async ionViewWillEnter() {
    await this.doRefresh();
  }

  async doRefresh(event?) {
    this.firstLoad = true;
    this.contatosLoaded = false;
    this.contatos = [];
    await this.loadData();
    event && event.target.complete();
  }

  async modalEditContato(contato) {
    const modal = await this.modalController.create({
      component: MeusContatosEditPage,
      componentProps: { contato }
    });
    await modal.present();

    const response = await modal.onWillDismiss();
    if (response) {
      this.loadData();
    }
  }

  async loadData(eventInfinite?){
   this.params.offset  = this.contatos.length;
   await this.contatoService.list(this.params).then((response) => {
      
      this.canLoadMore = response.length === 0 ? false : true;
      this.contatos = [...this.contatos, ...response];
      this.contatosLoaded = true;
      this.firstLoad = false;
    }).catch((err) => {
      
    });

    eventInfinite && eventInfinite.target.complete();
  }

  async modalCreateContato() {
    const modal = await this.modalController.create({
      component: MeusContatosCreatePage,
    });
    await modal.present();

    const response = await modal.onWillDismiss();
    if (response) {
      this.doRefresh();
    }
  }

  async deleteContato(id) {
    this.contatoService
      .delete(id)
      .then(async response => {
        this.loadData();
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Contato deletado corretamente.",
          buttons: ["OK"]
        });
        alert.present();
      })
      .catch(async error => {
        
        const alert = await this.alertController.create({
          header: "Ops",
          message: "Erro desconhecido, verifique sua conexão",
          buttons: ["OK"]
        });
        alert.present();
      });
  }

  async delete(id) {
    const alert = await this.alertController.create({
      header: "Deletar contato",
      message: "Essa operação não pode ser revertida, deseja continuar?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {}
        },
        {
          text: "Confirmo",
          handler: () => {
            this.deleteContato(id);
          }
        }
      ]
    });

    await alert.present();
  }


  dismiss(){
    this.modalController.dismiss();
  }

}
