<ion-header>
  <ion-toolbar color="pallete4">
    <ion-title>Atualização disponível</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">  
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content">
    <img class="logo" src="assets/img/loading.png" />
    <h4 class="message">{{ message }}</h4>
    <ion-button [hidden]="deviceType !== 'browser'" (click)="updateBrowser()">
      Atualizar o app {{ settings.brand_full }}
    </ion-button>
    <div class="buttonUpdate" [hidden]="deviceType !== 'ios'" (click)="updateMobileIos()">
      <img src="assets/img/ios-button.png" />
    </div>
    <div class="buttonUpdate" [hidden]="deviceType !== 'android'" (click)="updateMobileAndroid()">
      <img src="assets/img/android-button.png" />
    </div>
  </div>
</ion-content>
