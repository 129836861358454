import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

export class AppModule {}

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/common/loading/loading.module").then(
        (m) => m.LoadingPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/common/auth/login/login.module").then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./modals/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/common/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "integradores",
    loadChildren: () =>
      import(
        "./pages/management/integrador/integrador-list/integrador-list.module"
      ).then((m) => m.IntegradorListPageModule),
  },
  {
    path: "usuarios-list",
    loadChildren: () =>
      import(
        "./pages/management/usuarios/usuarios-list/usuarios-list.module"
      ).then((m) => m.UsuariosListPageModule),
  },
  {
    path: "condominio-select",
    loadChildren: () =>
      import("./modals/condominio-select/condominio-select.module").then(
        (m) => m.CondominioSelectPageModule
      ),
  },
  {
    path: "condominio-filtro",
    loadChildren: () =>
      import("./modals/condominio-filtro/condominio-filtro.module").then(
        (m) => m.CondominioFiltroPageModule
      ),
  },
  {
    path: "usuario-edit",
    loadChildren: () =>
      import("./modals/usuario-edit/usuario-edit.module").then(
        (m) => m.UsuarioEditPageModule
      ),
  },
  {
    path: "recuperar-senha/:token",
    loadChildren: () =>
      import("./pages/common/auth/recuperar-senha/recuperar-senha.module").then(
        (m) => m.RecuperarSenhaPageModule
      ),
  },
  {
    path: "usuario-info",
    loadChildren: () =>
      import("./modals/usuario-info/usuario-info.module").then(
        (m) => m.UsuarioInfoPageModule
      ),
  },
  {
    path: "usuario-permissao",
    loadChildren: () =>
      import("./modals/usuario-permissao/usuario-permissao.module").then(
        (m) => m.UsuarioPermissaoPageModule
      ),
  },
  {
    path: "usuario-novo",
    loadChildren: () =>
      import("./modals/usuario-novo/usuario-novo.module").then(
        (m) => m.UsuarioNovoPageModule
      ),
  },
  {
    path: "integrador-novo",
    loadChildren: () =>
      import(
        "./pages/management/integrador/integrador-novo/integrador-novo.module"
      ).then((m) => m.IntegradorNovoPageModule),
  },
  {
    path: "integrador-editar/:id",
    loadChildren: () =>
      import(
        "./pages/management/integrador/integrador-edit/integrador-edit.module"
      ).then((m) => m.IntegradorEditPageModule),
  },
  {
    path: "comunicados-adm",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-list/comunicado-list.module"
      ).then((m) => m.ComunicadoListPageModule),
  },
  {
    path: "comunicados-adm-edit/:id",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-edit/comunicado-edit.module"
      ).then((m) => m.ComunicadoEditPageModule),
  },
  {
    path: "comunicados-adm-novo",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-create/comunicado-create.module"
      ).then((m) => m.ComunicadoCreatePageModule),
  },
  {
    path: "condominio-troca",
    loadChildren: () =>
      import("./modals/condominio-troca/condominio-troca.module").then(
        (m) => m.CondominioTrocaPageModule
      ),
  },
  {
    path: "morador-busca",
    loadChildren: () =>
      import("./modals/morador-busca/morador-busca.module").then(
        (m) => m.MoradorBuscaPageModule
      ),
  },
  {
    path: "condominio-editar/:id",
    loadChildren: () =>
      import(
        "./pages/management/condominio/condominio-edit/condominio-edit.module"
      ).then((m) => m.CondominioEditPageModule),
  },
  {
    path: "condominio-novo",
    loadChildren: () =>
      import(
        "./pages/management/condominio/condominio-novo/condominio-novo.module"
      ).then((m) => m.CondominioNovoPageModule),
  },
  {
    path: "condominios",
    loadChildren: () =>
      import(
        "./pages/management/condominio/condominio-list/condominio-list.module"
      ).then((m) => m.CondominioListPageModule),
  },
  {
    path: "migracao",
    loadChildren: () =>
      import("./pages/management/migracao/migracao.module").then(
        (m) => m.MigracaoPageModule
      ),
  },
  {
    path: "ativar-conta/:chave/:id_usuario",
    loadChildren: () =>
      import("./pages/common/auth/ativar-conta/ativar-conta.module").then(
        (m) => m.AtivarContaPageModule
      ),
  },
  {
    path: "ativar-conta/:chave/:id_usuario/:condominioUUID",
    loadChildren: () =>
      import("./pages/common/auth/ativar-conta/ativar-conta.module").then(
        (m) => m.AtivarContaPageModule
      ),
  },
  {
    path: "condominio/info/:id",
    loadChildren: () =>
      import(
        "./pages/management/condominio/condominio-view/condominio-view.module"
      ).then((m) => m.CondominioViewPageModule),
  },
  {
    path: "categorias-adm",
    loadChildren: () =>
      import(
        "./pages/admin/categoria/categorias-list/categorias-list.module"
      ).then((m) => m.CategoriasListPageModule),
  },
  {
    path: "categoria-adm/:id",
    loadChildren: () =>
      import(
        "./pages/admin/categoria/categoria-edit/categoria-edit.module"
      ).then((m) => m.CategoriaEditPageModule),
  },
  {
    path: "categoria-adm",
    loadChildren: () =>
      import(
        "./pages/admin/categoria/categoria-novo/categoria-novo.module"
      ).then((m) => m.CategoriaNovoPageModule),
  },
  {
    path: "documentos-adm",
    loadChildren: () =>
      import(
        "./pages/admin/documento/documento-list/documento-list.module"
      ).then((m) => m.DocumentoListPageModule),
  },
  {
    path: "documento-adm-editar/:id",
    loadChildren: () =>
      import(
        "./pages/admin/documento/documento-edit/documento-edit.module"
      ).then((m) => m.DocumentoEditPageModule),
  },
  {
    path: "documento-adm-novo",
    loadChildren: () =>
      import(
        "./pages/admin/documento/documento-create/documento-create.module"
      ).then((m) => m.DocumentoCreatePageModule),
  },
  {
    path: "documento-info",
    loadChildren: () =>
      import("./modals/documento-info/documento-info.module").then(
        (m) => m.DocumentoInfoPageModule
      ),
  },
  {
    path: "pets-adm",
    loadChildren: () =>
      import("./pages/admin/pet/pet-list/pet-list.module").then(
        (m) => m.PetListPageModule
      ),
  },
  {
    path: "pets-adm-novo",
    loadChildren: () =>
      import("./pages/admin/pet/pet-novo/pet-novo.module").then(
        (m) => m.PetNovoPageModule
      ),
  },
  {
    path: "pets-adm-edit/:id",
    loadChildren: () =>
      import("./pages/admin/pet/pet-edit/pet-edit.module").then(
        (m) => m.PetEditPageModule
      ),
  },
  {
    path: "especie-adm/:id",
    loadChildren: () =>
      import("./pages/admin/especie/especie-edit/especie-edit.module").then(
        (m) => m.EspecieEditPageModule
      ),
  },
  {
    path: "especies-adm",
    loadChildren: () =>
      import("./pages/admin/especie/especie-list/especie-list.module").then(
        (m) => m.EspecieListPageModule
      ),
  },
  {
    path: "especie-adm",
    loadChildren: () =>
      import("./pages/admin/especie/especie-novo/especie-novo.module").then(
        (m) => m.EspecieNovoPageModule
      ),
  },
  {
    path: "funcionarios-adm",
    loadChildren: () =>
      import(
        "./pages/admin/funcionario/funcionario-list/funcionario-list.module"
      ).then((m) => m.FuncionarioListPageModule),
  },
  {
    path: "funcionarios-adm-novo",
    loadChildren: () =>
      import(
        "./pages/admin/funcionario/funcionario-create/funcionario-create.module"
      ).then((m) => m.FuncionarioCreatePageModule),
  },
  {
    path: "funcionarios-adm-editar/:id",
    loadChildren: () =>
      import(
        "./pages/admin/funcionario/funcionario-edit/funcionario-edit.module"
      ).then((m) => m.FuncionarioEditPageModule),
  },
  {
    path: "moradores-adm",
    loadChildren: () =>
      import("./pages/admin/morador/morador-list/morador-list.module").then(
        (m) => m.MoradorListPageModule
      ),
  },
  {
    path: "moradores-adm/:unidade",
    loadChildren: () =>
      import("./pages/admin/morador/morador-list/morador-list.module").then(
        (m) => m.MoradorListPageModule
      ),
  },
  {
    path: "moradores-adm-novo",
    loadChildren: () =>
      import("./pages/admin/morador/morador-create/morador-create.module").then(
        (m) => m.MoradorCreatePageModule
      ),
  },
  {
    path: "moradores-adm-editar/:id",
    loadChildren: () =>
      import("./pages/admin/morador/morador-edit/morador-edit.module").then(
        (m) => m.MoradorEditPageModule
      ),
  },
  {
    path: "unidades-adm",
    loadChildren: () =>
      import("./pages/admin/unidade/unidade-list/unidade-list.module").then(
        (m) => m.UnidadeListPageModule
      ),
  },
  {
    path: "motivos-de-visita",
    loadChildren: () =>
      import(
        "./pages/admin/visita-motivo/visita-motivo-list/visita-motivo-list.module"
      ).then((m) => m.VisitaMotivoListPageModule),
  },
  {
    path: "motivos-de-visita/:id/edit",
    loadChildren: () =>
      import(
        "./pages/admin/visita-motivo/visita-motivo-edit/visita-motivo-edit.module"
      ).then((m) => m.VisitaMotivoEditPageModule),
  },
  {
    path: "motivos-de-visita/create",
    loadChildren: () =>
      import(
        "./pages/admin/visita-motivo/visita-motivo-create/visita-motivo-create.module"
      ).then((m) => m.VisitaMotivoCreatePageModule),
  },
  {
    path: "assembleias",
    loadChildren: () =>
      import(
        "./pages/morador/assembleia/assembleia-list/assembleia-list.module"
      ).then((m) => m.AssembleiaListPageModule),
  },
  {
    path: "pauta-info",
    loadChildren: () =>
      import("./pages/morador/assembleia/pauta-info/pauta-info.module").then(
        (m) => m.PautaInfoPageModule
      ),
  },
  {
    path: "pauta-list",
    loadChildren: () =>
      import("./pages/morador/assembleia/pauta-list/pauta-list.module").then(
        (m) => m.PautaListPageModule
      ),
  },
  {
    path: "areas-comuns",
    loadChildren: () =>
      import("./pages/morador/reserva/area-list/area-list.module").then(
        (m) => m.AreaListPageModule
      ),
  },
  {
    path: "areas-comuns/:page",
    loadChildren: () =>
      import("./pages/morador/reserva/area-list/area-list.module").then(
        (m) => m.AreaListPageModule
      ),
  },
  {
    path: "reservar/:id",
    loadChildren: () =>
      import("./pages/morador/reserva/reservar/reservar.module").then(
        (m) => m.ReservarPageModule
      ),
  },
  {
    path: "reserva-list",
    loadChildren: () =>
      import("./pages/morador/reserva/reserva-list/reserva-list.module").then(
        (m) => m.ReservaListPageModule
      ),
  },
  {
    path: "cameras",
    loadChildren: () =>
      import("./pages/morador/camera/camera-list/camera-list.module").then(
        (m) => m.CameraListPageModule
      ),
  },
  {
    path: "cameras/:id",
    loadChildren: () =>
      import("./pages/morador/camera/camera-info/camera-info.module").then(
        (m) => m.CameraInfoPageModule
      ),
  },
  {
    path: "comunicados",
    loadChildren: () =>
      import(
        "./pages/morador/comunicado/comunicado-list/comunicado-list.module"
      ).then((m) => m.ComunicadoListPageModule),
  },
  {
    path: "documentos",
    loadChildren: () =>
      import(
        "./pages/morador/documento/documento-list/documento-list.module"
      ).then((m) => m.DocumentoListPageModule),
  },
  {
    path: "pets",
    loadChildren: () =>
      import("./pages/morador/pet/pet-list/pet-list.module").then(
        (m) => m.PetListPageModule
      ),
  },
  {
    path: "pets-detalhes/:id",
    loadChildren: () =>
      import("./pages/morador/pet/pet-info/pet-info.module").then(
        (m) => m.PetInfoPageModule
      ),
  },
  {
    path: "pets-editar/:id",
    loadChildren: () =>
      import("./pages/morador/pet/pet-edit/pet-edit.module").then(
        (m) => m.PetEditPageModule
      ),
  },
  {
    path: "pets-novo",
    loadChildren: () =>
      import("./pages/morador/pet/pet-create/pet-create.module").then(
        (m) => m.PetCreatePageModule
      ),
  },
  {
    path: "ocorrencias",
    loadChildren: () =>
      import(
        "./pages/morador/ocorrencia/ocorrencia-list/ocorrencia-list.module"
      ).then((m) => m.OcorrenciaListPageModule),
  },
  {
    path: "agenda-adm",
    loadChildren: () =>
      import("./pages/morador/agenda/agenda-list/agenda-list.module").then(
        (m) => m.AgendaListPageModule
      ),
  },
  {
    path: "agenda-list",
    loadChildren: () =>
      import("./pages/morador/agenda/agenda-list/agenda-list.module").then(
        (m) => m.AgendaListPageModule
      ),
  },
  {
    path: "agenda-create",
    loadChildren: () =>
      import("./pages/morador/agenda/agenda-create/agenda-create.module").then(
        (m) => m.AgendaCreatePageModule
      ),
  },
  {
    path: "agenda-info",
    loadChildren: () =>
      import("./pages/morador/agenda/agenda-info/agenda-info.module").then(
        (m) => m.AgendaInfoPageModule
      ),
  },
  {
    path: "agenda-edit",
    loadChildren: () =>
      import("./pages/morador/agenda/agenda-edit/agenda-edit.module").then(
        (m) => m.AgendaEditPageModule
      ),
  },
  {
    path: "perfil-info",
    loadChildren: () =>
      import("./pages/common/perfil/perfil-info/perfil-info.module").then(
        (m) => m.PerfilInfoPageModule
      ),
  },
  {
    path: "perfil-edit",
    loadChildren: () =>
      import("./pages/common/perfil/perfil-edit/perfil-edit.module").then(
        (m) => m.PerfilEditPageModule
      ),
  },
  {
    path: "notificacoes-list",
    loadChildren: () =>
      import(
        "./pages/common/notificacoes/notificacoes-list/notificacoes-list.module"
      ).then((m) => m.NotificacoesListPageModule),
  },
  {
    path: "morador-list-filtros",
    loadChildren: () =>
      import("./modals/morador-list-filtros/morador-list-filtros.module").then(
        (m) => m.MoradorListFiltrosPageModule
      ),
  },
  {
    path: "fornecedores-adm",
    loadChildren: () =>
      import(
        "./pages/admin/fornecedor/fornecedor-list/fornecedor-list.module"
      ).then((m) => m.FornecedorListPageModule),
  },
  {
    path: "fornecedor-adm",
    loadChildren: () =>
      import(
        "./pages/admin/fornecedor/fornecedor-create/fornecedor-create.module"
      ).then((m) => m.FornecedorCreatePageModule),
  },
  {
    path: "fornecedor-adm/:id",
    loadChildren: () =>
      import(
        "./pages/admin/fornecedor/fornecedor-edit/fornecedor-edit.module"
      ).then((m) => m.FornecedorEditPageModule),
  },
  {
    path: "contratos-adm",
    loadChildren: () =>
      import("./pages/admin/contrato/contrato-list/contrato-list.module").then(
        (m) => m.ContratoListPageModule
      ),
  },
  {
    path: "contrato-adm/:id",
    loadChildren: () =>
      import("./pages/admin/contrato/contrato-edit/contrato-edit.module").then(
        (m) => m.ContratoEditPageModule
      ),
  },
  {
    path: "contrato-adm",
    loadChildren: () =>
      import(
        "./pages/admin/contrato/contrato-create/contrato-create.module"
      ).then((m) => m.ContratoCreatePageModule),
  },
  {
    path: "patrimonios-adm",
    loadChildren: () =>
      import(
        "./pages/admin/patrimonio/patrimonio-list/patrimonio-list.module"
      ).then((m) => m.PatrimonioListPageModule),
  },
  {
    path: "patrimonio-adm",
    loadChildren: () =>
      import(
        "./pages/admin/patrimonio/patrimonio-create/patrimonio-create.module"
      ).then((m) => m.PatrimonioCreatePageModule),
  },
  {
    path: "patrimonio-adm/:id",
    loadChildren: () =>
      import(
        "./pages/admin/patrimonio/patrimonio-edit/patrimonio-edit.module"
      ).then((m) => m.PatrimonioEditPageModule),
  },
  {
    path: "enquete-adm/:id",
    loadChildren: () =>
      import("./pages/admin/enquete/enquete-edit/enquete-edit.module").then(
        (m) => m.EnqueteEditPageModule
      ),
  },
  {
    path: "enquetes-adm",
    loadChildren: () =>
      import("./pages/admin/enquete/enquete-list/enquete-list.module").then(
        (m) => m.EnqueteListPageModule
      ),
  },
  {
    path: "enquete-adm-create",
    loadChildren: () =>
      import("./pages/admin/enquete/enquete-create/enquete-create.module").then(
        (m) => m.EnqueteCreatePageModule
      ),
  },
  {
    path: "veiculo-edit",
    loadChildren: () =>
      import("./modals/veiculo/veiculo-edit/veiculo-edit.module").then(
        (m) => m.VeiculoEditPageModule
      ),
  },
  {
    path: "veiculo-create",
    loadChildren: () =>
      import("./modals/veiculo/veiculo-create/veiculo-create.module").then(
        (m) => m.VeiculoCreatePageModule
      ),
  },
  {
    path: "contato-create",
    loadChildren: () =>
      import("./modals/contato/contato-create/contato-create.module").then(
        (m) => m.ContatoCreatePageModule
      ),
  },
  {
    path: "contato-edit",
    loadChildren: () =>
      import("./modals/contato/contato-edit/contato-edit.module").then(
        (m) => m.ContatoEditPageModule
      ),
  },
  {
    path: "unidade-create",
    loadChildren: () =>
      import("./modals/unidade/unidade-create/unidade-create.module").then(
        (m) => m.UnidadeCreatePageModule
      ),
  },
  {
    path: "unidade-edit",
    loadChildren: () =>
      import("./modals/unidade/unidade-edit/unidade-edit.module").then(
        (m) => m.UnidadeEditPageModule
      ),
  },
  {
    path: "areas-comuns-adm",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/area-comum-list/area-comum-list.module"
      ).then((m) => m.AreaComumListPageModule),
  },
  {
    path: "image-slider",
    loadChildren: () =>
      import("./modals/image-slider/image-slider.module").then(
        (m) => m.ImageSliderPageModule
      ),
  },
  {
    path: "reservas-adm",
    loadChildren: () =>
      import("./pages/admin/reservas/reservas-list/reservas-list.module").then(
        (m) => m.ReservasListPageModule
      ),
  },
  {
    path: "reservas-adm/:id_areacomum",
    loadChildren: () =>
      import("./pages/admin/reservas/reservas-list/reservas-list.module").then(
        (m) => m.ReservasListPageModule
      ),
  },
  {
    path: "reservar-adm/:id",
    loadChildren: () =>
      import("./pages/morador/reserva/reservar/reservar.module").then(
        (m) => m.ReservarPageModule
      ),
  },
  {
    path: "areas-comuns-adm-create",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-adm-create/areas-comuns-adm-create.module"
      ).then((m) => m.AreasComunsAdmCreatePageModule),
  },
  {
    path: "areas-comuns-adm-edit/:id",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-adm-edit/areas-comuns-adm-edit.module"
      ).then((m) => m.AreasComunsAdmEditPageModule),
  },
  {
    path: "home-loader",
    loadChildren: () =>
      import("./pages/common/home-loader/home-loader.module").then(
        (m) => m.HomeLoaderPageModule
      ),
  },
  {
    path: "home-funcionario",
    loadChildren: () =>
      import("./pages/common/home-funcionario/home-funcionario.module").then(
        (m) => m.HomeFuncionarioPageModule
      ),
  },
  {
    path: "home-master",
    loadChildren: () =>
      import("./pages/common/home-master/home-master.module").then(
        (m) => m.HomeMasterPageModule
      ),
  },
  {
    path: "home-administrador",
    loadChildren: () =>
      import(
        "./pages/common/home-administrador/home-administrador.module"
      ).then((m) => m.HomeAdministradorPageModule),
  },
  {
    path: "comunicados/:id",
    loadChildren: () =>
      import(
        "./pages/morador/comunicado/comunicado-details/comunicado-details.module"
      ).then((m) => m.ComunicadoDetailsPageModule),
  },
  {
    path: "enquetes",
    loadChildren: () =>
      import("./pages/morador/enquete/enquete-list/enquete-list.module").then(
        (m) => m.EnqueteListPageModule
      ),
  },
  {
    path: "enquete-details/:id",
    loadChildren: () =>
      import(
        "./pages/morador/enquete/enquete-details/enquete-details.module"
      ).then((m) => m.EnqueteDetailsPageModule),
  },
  {
    path: "morador-comunicado-search",
    loadChildren: () =>
      import(
        "./modals/morador-comunicado-search/morador-comunicado-search.module"
      ).then((m) => m.MoradorComunicadoSearchPageModule),
  },
  {
    path: "modal-morador-comunicado-comentarios",
    loadChildren: () =>
      import(
        "./modals/modal-morador-comunicado-comentarios/modal-morador-comunicado-comentarios.module"
      ).then((m) => m.ModalMoradorComunicadoComentariosPageModule),
  },
  {
    path: "home-integrador",
    loadChildren: () =>
      import("./pages/common/home-integrador/home-integrador.module").then(
        (m) => m.HomeIntegradorPageModule
      ),
  },
  {
    path: "document-viewer",
    loadChildren: () =>
      import("./modals/document-viewer/document-viewer.module").then(
        (m) => m.DocumentViewerPageModule
      ),
  },
  {
    path: "liberacao",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-tabs/liberacao-tabs.module"
      ).then((m) => m.LiberacaoTabsPageModule),
  },
  {
    path: "liberacao-unica",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-unica/liberacao-unica.module"
      ).then((m) => m.LiberacaoUnicaPageModule),
  },
  {
    path: "liberacao-recorrente",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-recorrente/liberacao-recorrente.module"
      ).then((m) => m.LiberacaoRecorrentePageModule),
  },
  {
    path: "liberacao-unica-acessos",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-unica-acessos/liberacao-unica-acessos.module"
      ).then((m) => m.LiberacaoUnicaAcessosPageModule),
  },
  {
    path: "liberacao-recorrente-acessos",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-recorrente-acessos/liberacao-recorrente-acessos.module"
      ).then((m) => m.LiberacaoRecorrenteAcessosPageModule),
  },
  {
    path: "liberacao-unica-create",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-unica-create/liberacao-unica-create.module"
      ).then((m) => m.LiberacaoUnicaCreatePageModule),
  },
  {
    path: "liberacao-recorrente-create",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-recorrente-create/liberacao-recorrente-create.module"
      ).then((m) => m.LiberacaoRecorrenteCreatePageModule),
  },
  {
    path: "liberacao-visitante-create",
    loadChildren: () =>
      import(
        "./modals/liberacao-visitante-create/liberacao-visitante-create.module"
      ).then((m) => m.LiberacaoVisitanteCreatePageModule),
  },
  {
    path: "liberacao-visitante-list",
    loadChildren: () =>
      import(
        "./modals/liberacao-visitante-list/liberacao-visitante-list.module"
      ).then((m) => m.LiberacaoVisitanteListPageModule),
  },
  {
    path: "liberacao-unica-qrcode",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-unica-qrcode/liberacao-unica-qrcode.module"
      ).then((m) => m.LiberacaoUnicaQrcodePageModule),
  },
  {
    path: "configuracoes",
    loadChildren: () =>
      import("./pages/morador/configuracoes/configuracoes.module").then(
        (m) => m.ConfiguracoesPageModule
      ),
  },
  {
    path: "ajuda",
    loadChildren: () =>
      import("./pages/morador/ajuda/ajuda.module").then(
        (m) => m.AjudaPageModule
      ),
  },
  {
    path: "perfil/:id",
    loadChildren: () =>
      import("./pages/morador/perfil/perfil.module").then(
        (m) => m.PerfilPageModule
      ),
  },
  {
    path: "meu-perfil",
    loadChildren: () =>
      import("./pages/morador/meu-perfil/meu-perfil.module").then(
        (m) => m.MeuPerfilPageModule
      ),
  },
  {
    path: "vacinas-list",
    loadChildren: () =>
      import("./pages/morador/pet/vacinas-list/vacinas-list.module").then(
        (m) => m.VacinasListPageModule
      ),
  },
  {
    path: "vacinas-create",
    loadChildren: () =>
      import("./pages/morador/pet/vacinas-create/vacinas-create.module").then(
        (m) => m.VacinasCreatePageModule
      ),
  },
  {
    path: "privacidade",
    loadChildren: () =>
      import("./pages/morador/privacidade/privacidade.module").then(
        (m) => m.PrivacidadePageModule
      ),
  },
  {
    path: "grupos-edit-adm/:id",
    loadChildren: () =>
      import("./pages/admin/grupos/grupos-edit/grupos-edit.module").then(
        (m) => m.GruposEditPageModule
      ),
  },
  {
    path: "grupos-create-adm",
    loadChildren: () =>
      import("./pages/admin/grupos/grupos-create/grupos-create.module").then(
        (m) => m.GruposCreatePageModule
      ),
  },
  {
    path: "grupos-adm",
    loadChildren: () =>
      import("./pages/admin/grupos/grupos-list/grupos-list.module").then(
        (m) => m.GruposListPageModule
      ),
  },
  {
    path: "meus-contatos",
    loadChildren: () =>
      import("./pages/morador/meus-contatos/meus-contatos.module").then(
        (m) => m.MeusContatosPageModule
      ),
  },
  {
    path: "meus-contatos-edit",
    loadChildren: () =>
      import(
        "./pages/morador/meus-contatos-edit/meus-contatos-edit.module"
      ).then((m) => m.MeusContatosEditPageModule),
  },
  {
    path: "meus-contatos-create",
    loadChildren: () =>
      import(
        "./pages/morador/meus-contatos-create/meus-contatos-create.module"
      ).then((m) => m.MeusContatosCreatePageModule),
  },
  {
    path: "meus-veiculos-create",
    loadChildren: () =>
      import(
        "./pages/morador/meus-veiculos-create/meus-veiculos-create.module"
      ).then((m) => m.MeusVeiculosCreatePageModule),
  },
  {
    path: "meus-veiculos-edit",
    loadChildren: () =>
      import(
        "./pages/morador/meus-veiculos-edit/meus-veiculos-edit.module"
      ).then((m) => m.MeusVeiculosEditPageModule),
  },
  {
    path: "meus-veiculos",
    loadChildren: () =>
      import("./pages/morador/meus-veiculos/meus-veiculos.module").then(
        (m) => m.MeusVeiculosPageModule
      ),
  },
  {
    path: "meu-perfil-editar",
    loadChildren: () =>
      import("./pages/morador/meu-perfil-editar/meu-perfil-editar.module").then(
        (m) => m.MeuPerfilEditarPageModule
      ),
  },
  {
    path: "familiar-create",
    loadChildren: () =>
      import("./pages/morador/familiar-create/familiar-create.module").then(
        (m) => m.FamiliarCreatePageModule
      ),
  },
  {
    path: "familiar-edit",
    loadChildren: () =>
      import("./pages/morador/familiar-edit/familiar-edit.module").then(
        (m) => m.FamiliarEditPageModule
      ),
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./pages/morador/chat/chat.module").then((m) => m.ChatPageModule),
  },
  {
    path: "chat-search",
    loadChildren: () =>
      import("./pages/morador/chat-search/chat-search.module").then(
        (m) => m.ChatSearchPageModule
      ),
  },
  {
    path: "chat-conversa",
    loadChildren: () =>
      import("./pages/morador/chat-conversa/chat-conversa.module").then(
        (m) => m.ChatConversaPageModule
      ),
  },
  {
    path: "reservar-form",
    loadChildren: () =>
      import("./pages/morador/reserva/reservar-form/reservar-form.module").then(
        (m) => m.ReservarFormPageModule
      ),
  },
  {
    path: "entregas",
    loadChildren: () =>
      import("./pages/morador/entrega/entrega-list/entrega-list.module").then(
        (m) => m.EntregaListPageModule
      ),
  },
  {
    path: "entrega-view",
    loadChildren: () =>
      import("./pages/morador/entrega/entrega-view/entrega-view.module").then(
        (m) => m.EntregaViewPageModule
      ),
  },
  {
    path: "entrega-busca",
    loadChildren: () =>
      import("./pages/morador/entrega/entrega-busca/entrega-busca.module").then(
        (m) => m.EntregaBuscaPageModule
      ),
  },
  {
    path: "correspondencias-adm",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-list/correspondencias-list.module"
      ).then((m) => m.CorrespondenciasListPageModule),
  },
  {
    path: "correspondencias-adm/:code",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-list/correspondencias-list.module"
      ).then((m) => m.CorrespondenciasListPageModule),
  },
  {
    path: "correspondencias-qrcode",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-qrcode/correspondencias-qrcode.module"
      ).then((m) => m.CorrespondenciasQrcodePageModule),
  },
  {
    path: "correspondencias-edit",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-edit/correspondencias-edit.module"
      ).then((m) => m.CorrespondenciasEditPageModule),
  },
  {
    path: "correspondencias-view",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-view/correspondencias-view.module"
      ).then((m) => m.CorrespondenciasViewPageModule),
  },
  {
    path: "correspondencias-create",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-create/correspondencias-create.module"
      ).then((m) => m.CorrespondenciasCreatePageModule),
  },
  {
    path: "search-list",
    loadChildren: () =>
      import("./pages/admin/search-list/search-list.module").then(
        (m) => m.SearchListPageModule
      ),
  },
  {
    path: "correspondencias-tipos",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-tipos/correspondencias-tipos.module"
      ).then((m) => m.CorrespondenciasTiposPageModule),
  },
  {
    path: "correspondencias-embalagens",
    loadChildren: () =>
      import(
        "./pages/admin/correspondencia/correspondencias-embalagens/correspondencias-embalagens.module"
      ).then((m) => m.CorrespondenciasEmbalagensPageModule),
  },
  {
    path: "administracao",
    loadChildren: () =>
      import("./pages/morador/administracao/administracao.module").then(
        (m) => m.AdministracaoPageModule
      ),
  },
  {
    path: "leitor-qrcode/:page",
    loadChildren: () =>
      import("./pages/common/leitor-qrcode/leitor-qrcode.module").then(
        (m) => m.LeitorQrcodePageModule
      ),
  },
  {
    path: "ocorrencia-view",
    loadChildren: () =>
      import(
        "./pages/morador/ocorrencia/ocorrencia-view/ocorrencia-view.module"
      ).then((m) => m.OcorrenciaViewPageModule),
  },
  {
    path: "ocorrencia-create",
    loadChildren: () =>
      import(
        "./pages/morador/ocorrencia/ocorrencia-create/ocorrencia-create.module"
      ).then((m) => m.OcorrenciaCreatePageModule),
  },
  {
    path: "ticket-create",
    loadChildren: () =>
      import(
        "./pages/morador/ocorrencia/ticket-create/ticket-create.module"
      ).then((m) => m.TicketCreatePageModule),
  },
  {
    path: "ocorrencias-adm",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-list/ocorrencia-list.module"
      ).then((m) => m.OcorrenciaListPageModule),
  },
  {
    path: "ocorrencias-adm-create",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-create/ocorrencia-create.module"
      ).then((m) => m.OcorrenciaCreatePageModule),
  },
  {
    path: "ticket-create",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ticket-create/ticket-create.module"
      ).then((m) => m.TicketCreatePageModule),
  },
  {
    path: "ocorrencia-view",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-view/ocorrencia-view.module"
      ).then((m) => m.OcorrenciaViewPageModule),
  },
  {
    path: "buscar-morador",
    loadChildren: () =>
      import("./pages/admin/buscar-morador/buscar-morador.module").then(
        (m) => m.BuscarMoradorPageModule
      ),
  },
  {
    path: "buscar-funcionario",
    loadChildren: () =>
      import("./pages/admin/buscar-funcionario/buscar-funcionario.module").then(
        (m) => m.BuscarFuncionarioPageModule
      ),
  },
  {
    path: "visita-unica",
    loadChildren: () =>
      import("./pages/morador/liberacao/visita-unica/visita-unica.module").then(
        (m) => m.VisitaUnicaPageModule
      ),
  },
  {
    path: "visita-recorrente",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/visita-recorrente/visita-recorrente.module"
      ).then((m) => m.VisitaRecorrentePageModule),
  },
  {
    path: "notification",
    loadChildren: () =>
      import("./pages/common/notification/notification.module").then(
        (m) => m.NotificationPageModule
      ),
  },
  {
    path: "comunicado-comentarios",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-comentarios/comunicado-comentarios.module"
      ).then((m) => m.ComunicadoComentariosPageModule),
  },
  {
    path: "ativar-conta-social",
    loadChildren: () =>
      import(
        "./pages/common/auth/ativar-conta-social/ativar-conta-social.module"
      ).then((m) => m.AtivarContaSocialPageModule),
  },
  {
    path: "termos-de-uso",
    loadChildren: () =>
      import("./pages/morador/termos-de-uso/termos-de-uso.module").then(
        (m) => m.TermosDeUsoPageModule
      ),
  },
  {
    path: "alerta-detalhes",
    loadChildren: () =>
      import("./pages/morador/alerta-detalhes/alerta-detalhes.module").then(
        (m) => m.AlertaDetalhesPageModule
      ),
  },
  {
    path: "liberacao-recorrente-qrcode",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacao-recorrente-qrcode/liberacao-recorrente-qrcode.module"
      ).then((m) => m.LiberacaoRecorrenteQrcodePageModule),
  },
  {
    path: "acesso-tablet-adm",
    loadChildren: () =>
      import("./pages/admin/acesso-tablet/acesso-tablet.module").then(
        (m) => m.AcessoTabletPageModule
      ),
  },
  {
    path: "cadastro-facial-morador",
    loadChildren: () =>
      import(
        "./pages/admin/cadastro-facial-morador/cadastro-facial-morador.module"
      ).then((m) => m.CadastroFacialMoradorPageModule),
  },
  {
    path: "mudancas",
    loadChildren: () =>
      import("./pages/morador/mudancas/mudanca-list/mudanca-list.module").then(
        (m) => m.MudancaListPageModule
      ),
  },
  {
    path: "mudancas/nova-mudanca",
    loadChildren: () =>
      import(
        "./pages/morador/mudancas/mudanca-create/mudanca-create.module"
      ).then((m) => m.MudancaCreatePageModule),
  },
  {
    path: "mudancas/nova-mudanca-adm",
    loadChildren: () =>
      import(
        "./pages/morador/mudancas/mudanca-create/mudanca-create.module"
      ).then((m) => m.MudancaCreatePageModule),
  },
  {
    path: "mudancas-adm",
    loadChildren: () =>
      import("./pages/admin/mudancas/mudancas-list/mudancas-list.module").then(
        (m) => m.MudancasListPageModule
      ),
  },
  {
    path: "mudancas-adm-edit",
    loadChildren: () =>
      import("./pages/admin/mudancas/mudancas-edit/mudancas-edit.module").then(
        (m) => m.MudancasEditPageModule
      ),
  },
  {
    path: "mudanca-edit",
    loadChildren: () =>
      import("./pages/morador/mudancas/mudanca-edit/mudanca-edit.module").then(
        (m) => m.MudancaEditPageModule
      ),
  },
  {
    path: "enquete-info",
    loadChildren: () =>
      import("./pages/admin/enquete/enquete-info/enquete-info.module").then(
        (m) => m.EnqueteInfoPageModule
      ),
  },
  {
    path: "comunicado-curtidas",
    loadChildren: () =>
      import("./modals/comunicado-curtidas/comunicado-curtidas.module").then(
        (m) => m.ComunicadoCurtidasPageModule
      ),
  },
  {
    path: "mudancas-aprovadas-hoje",
    loadChildren: () =>
      import(
        "./pages/admin/mudancas/mudancas-aprovadas-hoje/mudancas-aprovadas-hoje.module"
      ).then((m) => m.MudancasAprovadasHojePageModule),
  },
  {
    path: "app-update",
    loadChildren: () =>
      import("./pages/common/app-update/app-update.module").then(
        (m) => m.AppUpdatePageModule
      ),
  },
  {
    path: "morador-facial",
    loadChildren: () =>
      import("./pages/admin/morador/morador-facial/morador-facial.module").then(
        (m) => m.MoradorFacialPageModule
      ),
  },
  {
    path: "link-liberacao-recorrente/:token",
    loadChildren: () =>
      import(
        "./pages/common/link-liberacao-recorrente/link-liberacao-recorrente.module"
      ).then((m) => m.LinkLiberacaoRecorrentePageModule),
  },
  {
    path: "liberacoes-unicas",
    loadChildren: () =>
      import(
        "./pages/morador/liberacao/liberacoes-unicas/liberacoes-unicas.module"
      ).then((m) => m.LiberacoesUnicasPageModule),
  },
  {
    path: "administracao/configuracoes",
    loadChildren: () =>
      import("./pages/admin/configuracoes-menu/configuracoes-menu.module").then(
        (m) => m.ConfiguracoesMenuPageModule
      ),
  },
  {
    path: "administracao/configuracoes/visitas",
    loadChildren: () =>
      import("./pages/admin/configuracoes/visitas/visitas.module").then(
        (m) => m.VisitasPageModule
      ),
  },
  {
    path: "administracao/configuracoes/comunicados",
    loadChildren: () =>
      import("./pages/admin/configuracoes/comunicados/comunicados.module").then(
        (m) => m.ComunicadosPageModule
      ),
  },
  {
    path: "administracao/configuracoes/mudancas",
    loadChildren: () =>
      import(
        "./pages/admin/mudancas/mudancas-config/mudancas-config.module"
      ).then((m) => m.MudancasConfigPageModule),
  },
  {
    path: "administracao/configuracoes/visita-unica",
    loadChildren: () =>
      import(
        "./pages/admin/configuracoes/visita-unica/visita-unica.module"
      ).then((m) => m.VisitaUnicaPageModule),
  },
  {
    path: "administracao/configuracoes/visita-recorrente",
    loadChildren: () =>
      import(
        "./pages/admin/configuracoes/visita-recorrente/visita-recorrente.module"
      ).then((m) => m.VisitaRecorrentePageModule),
  },
  {
    path: "comunicado-moderar",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-moderar/comunicado-moderar.module"
      ).then((m) => m.ComunicadoModerarPageModule),
  },
  {
    path: "comunicado-enviadopara",
    loadChildren: () =>
      import(
        "./pages/admin/comunicado/comunicado-enviadopara/comunicado-enviadopara.module"
      ).then((m) => m.ComunicadoEnviadoparaPageModule),
  },
  {
    path: "atendimento",
    loadChildren: () =>
      import("./pages/morador/atendimento/atendimento.module").then(
        (m) => m.AtendimentoPageModule
      ),
  },
  {
    path: "atendimento-adm",
    loadChildren: () =>
      import(
        "./pages/admin/atendimento/atendimento-list/atendimento-list.module"
      ).then((m) => m.AtendimentoListPageModule),
  },
  {
    path: "atendimento-adm-create",
    loadChildren: () =>
      import(
        "./pages/admin/atendimento/atendimento-get/atendimento-get.module"
      ).then((m) => m.AtendimentoGetPageModule),
  },
  {
    path: "atendimento-adm-edit/:id",
    loadChildren: () =>
      import(
        "./pages/admin/atendimento/atendimento-get/atendimento-get.module"
      ).then((m) => m.AtendimentoGetPageModule),
  },
  {
    path: "buscar-morador-default",
    loadChildren: () =>
      import(
        "./pages/admin/buscar-morador-default/buscar-morador-default.module"
      ).then((m) => m.BuscarMoradorDefaultPageModule),
  },
  {
    path: "grupos-usuarios",
    loadChildren: () =>
      import(
        "./pages/admin/grupos/grupos-usuarios/grupos-usuarios.module"
      ).then((m) => m.GruposUsuariosPageModule),
  },
  {
    path: "master/logs/:id_condominio",
    loadChildren: () =>
      import("./pages/management/logs/logs-list/logs-list.module").then(
        (m) => m.LogsListPageModule
      ),
  },
  {
    path: "ajustes/notificacoes",
    loadChildren: () =>
      import(
        "./pages/morador/notificacoes/notificacoes-config/notificacoes-config.module"
      ).then((m) => m.NotificacoesConfigPageModule),
  },
  {
    path: "ajustes",
    loadChildren: () =>
      import("./pages/morador/ajustes/ajustes.module").then(
        (m) => m.AjustesPageModule
      ),
  },
  {
    path: "nao-tenho-cadastro",
    loadChildren: () =>
      import(
        "./pages/common/auth/nao-tenho-cadastro/nao-tenho-cadastro.module"
      ).then((m) => m.NaoTenhoCadastroPageModule),
  },
  {
    path: "ocorrencia-categorias-list",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-categorias-list/ocorrencia-categorias-list.module"
      ).then((m) => m.OcorrenciaCategoriasListPageModule),
  },
  {
    path: "ocorrencia-categorias-create",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-categorias-create/ocorrencia-categorias-create.module"
      ).then((m) => m.OcorrenciaCategoriasCreatePageModule),
  },
  {
    path: "ocorrencia-categorias-edit",
    loadChildren: () =>
      import(
        "./pages/admin/ocorrencia/ocorrencia-categorias-edit/ocorrencia-categorias-edit.module"
      ).then((m) => m.OcorrenciaCategoriasEditPageModule),
  },
  {
    path: "saida-materiais",
    loadChildren: () =>
      import(
        "./pages/morador/saida-material/saida-material-list/saida-material-list.module"
      ).then((m) => m.SaidaMaterialListPageModule),
  },
  {
    path: "saida-material-create",
    loadChildren: () =>
      import(
        "./pages/morador/saida-material/saida-material-create/saida-material-create.module"
      ).then((m) => m.SaidaMaterialCreatePageModule),
  },
  {
    path: "saida-material-edit",
    loadChildren: () =>
      import(
        "./pages/morador/saida-material/saida-material-edit/saida-material-edit.module"
      ).then((m) => m.SaidaMaterialEditPageModule),
  },
  {
    path: "saida-materiais-adm",
    loadChildren: () =>
      import(
        "./pages/admin/saida-materiais/saida-materiais-admin/saida-materiais-admin.module"
      ).then((m) => m.SaidaMateriaisAdminPageModule),
  },
  {
    path: "saida-materiais-adm-view",
    loadChildren: () =>
      import(
        "./pages/admin/saida-materiais/saida-materiais-view/saida-materiais-view.module"
      ).then((m) => m.SaidaMateriaisViewPageModule),
  },
  {
    path: "integrador/whitelabel/:id",
    loadChildren: () =>
      import(
        "./pages/management/integrador/integrador-whitelabel/integrador-whitelabel.module"
      ).then((m) => m.IntegradorWhitelabelPageModule),
  },
  {
    path: "areas-comuns-profissionais/:id_area_comum",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-profissionais/areas-comuns-profissionais.module"
      ).then((m) => m.AreasComunsProfissionaisPageModule),
  },
  {
    path: "areas-comuns-profissionais",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-profissionais/areas-comuns-profissionais.module"
      ).then((m) => m.AreasComunsProfissionaisPageModule),
  },
  {
    path: "areas-comuns-profissionais-create",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-profissionais-create/areas-comuns-profissionais-create.module"
      ).then((m) => m.AreasComunsProfissionaisCreatePageModule),
  },
  {
    path: "areas-comuns-profissionais-edit",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-profissionais-edit/areas-comuns-profissionais-edit.module"
      ).then((m) => m.AreasComunsProfissionaisEditPageModule),
  },
  {
    path: "area-profissionais",
    loadChildren: () =>
      import(
        "./pages/morador/reserva/area-profissionais/area-profissionais.module"
      ).then((m) => m.AreaProfissionaisPageModule),
  },
  {
    path: "fc-licencas",
    loadChildren: () =>
      import(
        "./pages/management/fc-clientes/fc-clientes-list/fc-clientes-list.module"
      ).then((m) => m.FcClientesListPageModule),
  },
  {
    path: "areas-comuns-grupos",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-grupos/areas-comuns-grupos.module"
      ).then((m) => m.AreasComunsGruposPageModule),
  },
  {
    path: "areas-comuns-grupos-create",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-grupos-create/areas-comuns-grupos-create.module"
      ).then((m) => m.AreasComunsGruposCreatePageModule),
  },
  {
    path: "areas-comuns-grupos-edit",
    loadChildren: () =>
      import(
        "./pages/admin/areas-comuns/areas-comuns-grupos-edit/areas-comuns-grupos-edit.module"
      ).then((m) => m.AreasComunsGruposEditPageModule),
  },
  {
    path: "permissoes-create",
    loadChildren: () =>
      import(
        "./pages/management/permissoes/permissoes-create/permissoes-create.module"
      ).then((m) => m.PermissoesCreatePageModule),
  },
  {
    path: "permissoes-edit/:id",
    loadChildren: () =>
      import(
        "./pages/management/permissoes/permissoes-edit/permissoes-edit.module"
      ).then((m) => m.PermissoesEditPageModule),
  },
  {
    path: "permissoes-list",
    loadChildren: () =>
      import(
        "./pages/management/permissoes/permissoes-list/permissoes-list.module"
      ).then((m) => m.PermissoesListPageModule),
  },
  {
    path: "leitor-mobile-create",
    loadChildren: () =>
      import(
        "./pages/admin/leitor-mobile/leitor-mobile-create/leitor-mobile-create.module"
      ).then((m) => m.LeitorMobileCreatePageModule),
  },
  {
    path: "leitor-mobile-edit/:id",
    loadChildren: () =>
      import(
        "./pages/admin/leitor-mobile/leitor-mobile-edit/leitor-mobile-edit.module"
      ).then((m) => m.LeitorMobileEditPageModule),
  },
  {
    path: "leitor-mobile-adm",
    loadChildren: () =>
      import(
        "./pages/admin/leitor-mobile/leitor-mobile-list/leitor-mobile-list.module"
      ).then((m) => m.LeitorMobileListPageModule),
  },
  {
    path: "administracao/configuracoes/menu-morador",
    loadChildren: () =>
      import(
        "./pages/admin/configuracoes/menu-morador/menu-morador.module"
      ).then((m) => m.MenuMoradorPageModule),
  },
  {
    path: "formularios-list-admin",
    loadChildren: () =>
      import(
        "./pages/admin/formularios/formularios-list/formularios-list.module"
      ).then((m) => m.FormulariosListPageModule),
  },
  {
    path: "formularios-create-admin",
    loadChildren: () =>
      import(
        "./pages/admin/formularios/formularios-create/formularios-create.module"
      ).then((m) => m.FormulariosCreatePageModule),
  },
  {
    path: "formularios-edit-admin/:id",
    loadChildren: () =>
      import(
        "./pages/admin/formularios/formularios-edit/formularios-edit.module"
      ).then((m) => m.FormulariosEditPageModule),
  },
  {
    path: "formularios-list",
    loadChildren: () =>
      import(
        "./pages/morador/formularios/formularios-list/formularios-list.module"
      ).then((m) => m.FormulariosListPageModule),
  },
  {
    path: "formularios-resposta/:id",
    loadChildren: () =>
      import(
        "./pages/morador/formularios/formularios-resposta/formularios-resposta.module"
      ).then((m) => m.FormulariosRespostaPageModule),
  },
  {
    path: "formularios-respostas-admin/:id",
    loadChildren: () =>
      import(
        "./pages/admin/formularios/formularios-respostas/formularios-respostas.module"
      ).then((m) => m.FormulariosRespostasPageModule),
  },
  {
    path: "reservas-admin-conflitos/:area/:inicio/:fim",
    loadChildren: () =>
      import(
        "./pages/admin/reservas/reservas-conflitos/reservas-conflitos.module"
      ).then((m) => m.ReservasConflitosPageModule),
  },
  {
    path: "link-cadastro-titular/:token",
    loadChildren: () =>
      import(
        "./pages/common/link-cadastro-titular/link-cadastro-titular.module"
      ).then((m) => m.LinkCadastroTitularPageModule),
  },
  {
    path: "link-cadastro-morador/:token",
    loadChildren: () =>
      import(
        "./pages/common/link-cadastro-morador/link-cadastro-morador.module"
      ).then((m) => m.LinkCadastroMoradorPageModule),
  },
  {
    path: "link-cadastro-morador/:condominioUUID/:moradorUUID",
    loadChildren: () =>
      import(
        "./pages/common/link-cadastro-morador/link-cadastro-morador.module"
      ).then((m) => m.LinkCadastroMoradorPageModule),
  },
  {
    path: "link-liberacao/:token",
    loadChildren: () =>
      import("./pages/common/link-liberacao/link-liberacao.module").then(
        (m) => m.LinkLiberacaoPageModule
      ),
  },
  {
    path: "link-cadastro-facial/:token",
    loadChildren: () =>
      import(
        "./pages/common/link-cadastro-facial/link-cadastro-facial.module"
      ).then((m) => m.LinkCadastroFacialPageModule),
  },
  {
    path: "link-cadastro-facial/:condominioUUID/:moradorUUID",
    loadChildren: () =>
      import(
        "./pages/common/link-cadastro-facial/link-cadastro-facial.module"
      ).then((m) => m.LinkCadastroFacialPageModule),
  },
  {
    path: "liberacao-visitante/:token",
    loadChildren: () =>
      import(
        "./pages/common/liberacao-visitante/liberacao-visitante.module"
      ).then((m) => m.LiberacaoVisitantePageModule),
  },
  {
    path: "motivos-configuracoes",
    loadChildren: () =>
      import(
        "./components/motivos-configuracoes/motivos-configuracoes.module"
      ).then((m) => m.MotivosConfiguracoesPageModule),
  },
  {
    path: "location-adm",
    loadChildren: () =>
      import("./pages/admin/location-adm/location-adm.module").then(
        (m) => m.LocationAdmPageModule
      ),
  },
  {
    path: "morador-reserva/:id",
    loadChildren: () =>
      import("./pages/v2/morador/reservas/create/create.module").then(
        (m) => m.CreatePageModule
      ),
  },
  {
    path: "modal-reserva",
    loadChildren: () =>
      import(
        "./pages/v2/morador/reservas/modal-reserva/modal-reserva.module"
      ).then((m) => m.ModalReservaPageModule),
  },
  {
    path: "liberacao-acesso",
    loadChildren: () =>
      import(
        "./pages/admin/configuracoes/liberacao-acesso/liberacao-acesso.module"
      ).then((m) => m.LiberacaoAcessoPageModule),
  },
  {
    path: "create-liberacao-acesso",
    loadChildren: () =>
      import(
        "./modals/create-liberacao-acesso/create-liberacao-acesso.module"
      ).then((m) => m.CreateLiberacaoAcessoPageModule),
  },
  {
    path: "auditoria",
    loadChildren: () =>
      import(
        "./pages/management/auditoria/auditoria-list/auditoria-list.module"
      ).then((m) => m.AuditoriaListModule),
  },
  {
    path: "auditoria/:id",
    loadChildren: () =>
      import(
        "./pages/management/auditoria/auditoria-view/auditoria-view.module"
      ).then((m) => m.AuditoriaViewPageModule),
  },
  {
    path: "error403",
    loadChildren: () =>
      import("./pages/errors/error403/error403.module").then(
        (m) => m.Error403PageModule
      ),
  },
  {
    path: "tabela-de-horarios",
    loadChildren: () =>
      import(
        "./pages/admin/tabela-de-horarios/tabela-de-horarios-list/tabela-de-horarios-list.module"
      ).then((m) => m.TabelaDeHorariosListPageModule),
  },
  {
    path: "tabela-de-horarios/:id/edit",
    loadChildren: () =>
      import(
        "./pages/admin/tabela-de-horarios/tabela-de-horarios-edit/tabela-de-horarios-edit.module"
      ).then((m) => m.TabelaDeHorariosEditPageModule),
  },
  {
    path: "tabela-de-horarios/create",
    loadChildren: () =>
      import(
        "./pages/admin/tabela-de-horarios/tabela-de-horarios-create/tabela-de-horarios-create.module"
      ).then((m) => m.TabelaDeHorariosCreatePageModule),
  },
  {
    path: "condominio/import-csv/:id",
    loadChildren: () =>
      import(
        "./pages/management/condominio/condominio-import-csv/condominio-import-csv.module"
      ).then((m) => m.CondominioImportCsvPageModule),
  },
  {
    path: "morador/area-comum",
    loadChildren: () =>
      import("./pages/morador/custom/area-comum/area-comum.module").then(
        (m) => m.AreaComumPageModule
      ),
  },
  {
    path: "admin/paginas",
    loadChildren: () =>
      import("./pages/admin/paginas/paginas-lista/paginas-lista.module").then(
        (m) => m.PaginasListaPageModule
      ),
  },
  {
    path: "admin/paginas/nova",
    loadChildren: () =>
      import(
        "./pages/admin/paginas/paginas-criacao/paginas-criacao.module"
      ).then((m) => m.PaginasCriacaoPageModule),
  },
  {
    path: "admin/paginas/:id",
    loadChildren: () =>
      import("./pages/admin/paginas/paginas-edicao/paginas-edicao.module").then(
        (m) => m.PaginasEdicaoPageModule
      ),
  },
  {
    path: "paginas-grupo-form",
    loadChildren: () =>
      import("./modals/paginas-grupo-form/paginas-grupo-form.module").then(
        (m) => m.PaginasGrupoFormPageModule
      ),
  },
  {
    path: "morador/comunicados",
    loadChildren: () =>
      import("./pages/morador/custom/comunicados/comunicados.module").then(
        (m) => m.ComunicadosPageModule
      ),
  },
  {
    path: "grupo-categoria",
    loadChildren: () =>
      import("./components/grupo-categoria/grupo-categoria.module").then(
        (m) => m.GrupoCategoriaPageModule
      ),
  },
  {
    path: "morador/documentos",
    loadChildren: () =>
      import("./pages/morador/custom/documentos/documentos.module").then(
        (m) => m.DocumentosPageModule
      ),
  },
  {
    path: "morador/liberacao-visitas",
    loadChildren: () =>
      import(
        "./pages/morador/custom/liberacao-visitas/liberacao-visitas.module"
      ).then((m) => m.LiberacaoVisitasPageModule),
  },
  {
    path: "morador/link-externo/:id",
    loadChildren: () =>
      import("./pages/morador/custom/link-externo/link-externo.module").then(
        (m) => m.LinkExternoPageModule
      ),
  },
  {
    path: "morador/cameras",
    loadChildren: () =>
      import("./pages/morador/camera/camera-list/camera-list.module").then(
        (m) => m.CameraListPageModule
      ),
  },
  {
    path: "home-categorias/:id",
    loadChildren: () =>
      import(
        "./pages/morador/custom/home-categorias/home-categorias.module"
      ).then((m) => m.HomeCategoriasPageModule),
  },
  {
    path: "morador/reservas-surf",
    loadChildren: () =>
      import("./pages/morador/reserva/reservas-surf/reservas-surf.module").then(
        (m) => m.ReservasSurfPageModule
      ),
  },
  {
    path: "administracao/configuracoes/notificacoes",
    loadChildren: () =>
      import(
        "./pages/admin/configuracoes/notificacoes/notificacoes.module"
      ).then((m) => m.NotificacoesPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes /*{ preloadingStrategy: PreloadAllModules }*/),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
