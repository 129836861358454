<div  class="input-images ion-padding">
  <ion-button color="{{ color }}" (click)="choosePictures()" *ngIf="!icon">
    {{ title }}
  </ion-button>

  <ion-fab-button size="small" color="{{ color }}" (click)="choosePictures()" *ngIf="icon">
    <ion-icon name="{{ icon }}"></ion-icon>
  </ion-fab-button>
</div>


<input *ngIf="hasCamSupport" type="file" id="{{ idInputFile }}"  accept="image/*;capture=camera" capture="camera"  (change)="browser($event)" style="display: none;" />
<input *ngIf="!hasCamSupport" type="file" id="{{ idInputFile }}"  accept="image/*"  (change)="browser($event)" style="display: none;" />