import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';

@Injectable({
  providedIn: 'root'
})
export class ContatosService {

  constructor(private apiRequest: ApiRequestService) {}

  public list = async params => {
    return this.apiRequest.apiServer("contatos", params,).then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public tipos = async (params : any = {}) => {
    return this.apiRequest.apiServer("contatos/tipos", params).then((response) => {
      return response.data;
    });
  };

  public save = async (params : any = {}) => {
    return this.apiRequest.apiServer("contatos", params, "POST").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public update = async (params : any = {}) => {
    return this.apiRequest.apiServer("contatos/"+params.id, params, "PUT").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public delete = async id => {
    return this.apiRequest.apiServer("contatos/"+id, {}, "DELETE").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminDelete = async id => {
    return this.apiRequest.apiServer("admin/contatos/"+id, {}, "DELETE").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminSave = async (params : any = {}) => {
    return this.apiRequest.apiServer("admin/contatos", params, "POST").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminUpdate = async (params : any = {}) => {
    return this.apiRequest.apiServer("admin/contatos/"+params.id, params, "PUT").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminTipos = async (params : any = {}) => {
    return this.apiRequest.apiServer("admin/contatos/tipos", params).then((response) => {
      return response.data;
    });
  };
}
