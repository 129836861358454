<ion-header>
  <ion-toolbar color="primary">
    <ion-title slot="start">Configurações de notificação</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">  
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="full">
    <ion-label>Reservas</ion-label>
    <ion-toggle [checked]="!configs.reservas" (ionChange)="changePreference($event, 'reservas')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Comunicados</ion-label>
    <ion-toggle [checked]="!configs.comunicados" (ionChange)="changePreference($event, 'comunicados')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Documentos</ion-label>
    <ion-toggle [checked]="!configs.documentos" (ionChange)="changePreference($event, 'documentos')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Enquetes</ion-label>
    <ion-toggle [checked]="!configs.enquetes" (ionChange)="changePreference($event, 'enquetes')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Ocorrências</ion-label>
    <ion-toggle [checked]="!configs.ocorrencias" (ionChange)="changePreference($event, 'ocorrencias')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Correspondências</ion-label>
    <ion-toggle [checked]="!configs.correspondencias" (ionChange)="changePreference($event, 'correspondencias')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Mudanças</ion-label>
    <ion-toggle [checked]="!configs.mudancas" (ionChange)="changePreference($event, 'mudancas')"></ion-toggle>
  </ion-item>
  <ion-item lines="full">
    <ion-label>Saída de material</ion-label>
    <ion-toggle [checked]="!configs.saida_material" (ionChange)="changePreference($event, 'saida_material')"></ion-toggle>
  </ion-item>
</ion-content>
