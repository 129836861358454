const settings: any = {
  brand_line1: "Control",
  brand_line2: "Condo",
  brand_full: "Control Condo",
  id_condominio: false,
  custom_theme: false,
  onlyBrowserToken: false,
  main_color: "#3c8dbc",
  firebase_config: {
    apiKey: "AIzaSyAB17F0yNTlCQ9cbsaIakI-YHxJaXk35AY",
    authDomain: "push-284f8.firebaseapp.com",
    databaseURL: "https://push-284f8.firebaseio.com",
    projectId: "push-284f8",
    storageBucket: "push-284f8.appspot.com",
    messagingSenderId: "572833270659",
    appId: "1:572833270659:web:a6d001451b8cd8b8dd04b6",
  },
};
export default settings;
