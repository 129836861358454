import { Component, Input, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { Morador } from "src/app/entities/morador";
import { MinhacontaService } from "src/app/services/minhaconta.service";

@Component({
  selector: "app-familiar-edit",
  templateUrl: "./familiar-edit.page.html",
  styleUrls: ["./familiar-edit.page.scss"],
})
export class FamiliarEditPage implements OnInit {
  @Input("morador") morador: any = {
    parentesco: {
      id: 0,
    },
  };
  userPicture: String = "assets/img/avatarDefault.png";
  parentescos: any = [];
  loader;
  constructor(
    private alertController: AlertController,
    private minhacontaService: MinhacontaService,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.morador.foto && this.morador.foto.foto) {
      this.userPicture = this.morador.foto.foto;
    }
    this.loadParentescos();
    this.loadPermissions();
  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {
        const { permissions } = response.config;
      })
      .catch((err) => {});
  }

  async loadParentescos() {
    let parentescoId = this.morador.parentesco.id;
    this.morador.parentesco.id = 0;

    this.minhacontaService.parentescos().then((response) => {
      this.parentescos = response;
      this.morador.parentesco.id = parentescoId;
    });
  }
  async responseAvatar(picture) {
    this.morador.new_foto = picture.base64;
    this.userPicture = picture.base64;
  }

  async save() {
    this.loader = await this.loadingController.create({
      message: "Atualizando familiar...",
    });
    await this.loader.present();
    this.morador.usar_control_condo = this.morador.usar_control_condo ? 1 : 0;
    await this.minhacontaService
      .updateFamiliar(this.morador.id, this.morador)
      .then((response) => {
        this.loader.dismiss();
        this.modalController.dismiss({ status: "account_updated" });
      })
      .catch(async (errors) => {
        this.loader.dismiss();

        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"],
        });
        alert.present();
      });

    this.loader.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
