import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { licensePlatePattern } from "src/app/common/constants";
import { Veiculo } from 'src/app/entities/veiculo';
import { StorageService } from 'src/app/services/storage.service';
import { VeiculosService } from 'src/app/services/veiculos.service';
import vanillaMasker from "vanilla-masker";

@Component({
  selector: 'app-meus-veiculos-edit',
  templateUrl: './meus-veiculos-edit.page.html',
  styleUrls: ['./meus-veiculos-edit.page.scss'],
})
export class MeusVeiculosEditPage implements OnInit {
  @Input() veiculo: Veiculo;
  marcas: any[] = [];
  modelos: any[] = [];
  cores: any[] = [];
  marca_txt: String = "";
  modelo_txt: String = "";
  cor_txt: String = "";
  loader;
  foto: any = "";
  lpr: Boolean = false;
  userPicture: string = 'assets/img/car-placeholder.jpg';
  showLpr: Boolean = false;
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private veiculosService: VeiculosService,
    private modalController: ModalController,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.setup();
    let condominio = this.storageService.get('usuario_condominio').condominio
    this.showLpr = condominio.morador_cadastra_lpr === 1 ? true : false;
    this.lpr = this.veiculo.acionador_lpr ? true : false;
  }

  async setup() {
    this.loader = await this.loadingController.create({});
    this.loader.present();
    this.loadPlaca();
    this.loadFoto();
    await this.loadMarcas();
    await this.loadModelos();
    await this.loadCores();
    this.loader.dismiss();
  }

  loadFoto(){
    if(this.veiculo.foto && this.veiculo.foto.foto){
      this.userPicture = this.veiculo.foto.foto;
    }
  }

  loadPlaca() {
    this.placaChange(this.veiculo.placa, true);
  }

  async responseAvatar(picture) {
    this.foto= picture.base64;
    this.userPicture = picture.base64;
  }

  async loadMarcas() {
    return this.veiculosService.marcas().then(marcas => {
      this.marcas = marcas;
      this.marcaTxt();
    });
  }

  async loadModelos() {
    return this.veiculosService
      .modelos({ id_marca: this.veiculo.modelo.marca.id })
      .then(modelos => {
        this.modelos = modelos;
        this.modeloTxt();
      });
  }

  async loadCores() {
    return this.veiculosService.cores().then(cores => {
      this.cores = cores;
      this.corTxt();
    });
  }

  marcaTxt() {
    this.marcas.map(item => {
      if (item.id == this.veiculo.modelo.marca.id) {
        this.marca_txt = item.marca;
      }
    });
  }

  modeloTxt() {
    this.modelos.map(item => {
      if (item.id == this.veiculo.modelo.id) {
        this.modelo_txt = item.modelo;
      }
    });
  }

  corTxt() {
    this.cores.map(item => {
      if (item.id == this.veiculo.cor.id) {
        this.cor_txt = item.cor;
      }
    });
  }

  async update() {
    this.loader = await this.loadingController.create({});
    await this.loader.present();
    await this.updateVeiculo();
    this.loader.dismiss();
  }


  getPlacaCode(placa){
    return parseInt(placa.trim().replace('-', ''), 36);
  }

  async updateVeiculo() {
    let params = {
      id: this.veiculo.id,
      modelo: this.veiculo.modelo.id,
      cor: this.veiculo.cor.id,
      placa: this.veiculo.placa,
      foto: this.foto ? this.foto : "",
      lpr: this.lpr,
      veiculo_code: this.getPlacaCode(this.veiculo.placa)
    };
    
    await this.veiculosService
      .update(params)
      .then(async (result) => {
        this.dismiss();
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Veículo editado com sucesso.",
          buttons: ["OK"]
        });
        alert.present();
      })
      .catch(async errors => {
        
        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"]
        });
        alert.present();
      });
  }

  placaChange($event, force = false) {
    let pattern = vanillaMasker.toPattern(
      force ? $event : $event.target.value,
      licensePlatePattern
    ).toUpperCase();
    setTimeout(() => {
      this.veiculo.placa = pattern;
    }, 50);
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
