import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
	providedIn: "root",
})
export class FacialService {
	constructor(private apiRequest: ApiRequestService) { }

	public validar = async (dados) => {
		return this.apiRequest
			.apiServer("common/facial/validar", dados, 'POST');
	};

	public visitanteFotoOuFacial = (visitante) => {
		if (visitante && visitante.foto && visitante.foto.foto) return visitante.foto.foto;

		if (visitante && visitante.face) return visitante.face;

		return "assets/img/avatarDefault.png";
	};

	public getFileType = (base64) => {
		const body2 = { profilepic: base64 };
		let mimeType2 = body2.profilepic.match(/[^:/]\w+(?=;|,)/)[0];
		return mimeType2;
	}

	public base64ToAvatarFile = (base64) => {
		let fileName = 'avatar.' + this.getFileType(base64);

		return this.base64ToFile(base64, fileName);
	}

	public base64ToFile = (base64, fileName) => {
		var arr = base64.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], fileName, { type: mime });
	}
}
