import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {
  private reloadSubject: BehaviorSubject<any>;
  public reloadData: Observable<any>;

  constructor(
    private apiRequest: ApiRequestService,
  ) {
    this.reloadSubject = new BehaviorSubject("");
    this.reloadData = this.reloadSubject.asObservable();
  }

  public get reloadDataValue() {
    return this.reloadSubject.value;
  }

  public reload(){
    this.reloadSubject.next({});
  }

  public visualizar = async params => {
    return this.apiRequest.apiServer("notificacao/visualizar", params, "POST").then((response) => {
      this.reload();
      return response.data;
    });
  }

  public visualizar_todas = async params => {
    return this.apiRequest.apiServer("notificacao/visualizar_todas", params, "POST").then((response) => {
      this.reload();
      return response.data;
    });
  }

  public countMorador = async () => {
    return this.apiRequest.apiServer("notificacao/count",).then((response) => {
      return response.data;
    });
  }
}
