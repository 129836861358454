import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ContatosService } from 'src/app/services/contatos.service';

@Component({
  selector: 'app-meus-contatos-edit',
  templateUrl: './meus-contatos-edit.page.html',
  styleUrls: ['./meus-contatos-edit.page.scss'],
})
export class MeusContatosEditPage implements OnInit {
  @Input('contato') contato: any = {};
  tipos: any[] = [];
  loader;
  tipo_txt = "";
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private contatoService: ContatosService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.setup();
  }

  async setup() {
    this.loader = await this.loadingController.create({});
    this.loader.present();
    await this.loadTipos();
    this.loader.dismiss();
  }

  compareTipo(val1, val2) {
    return val1 == val2;
  }

  changeTipo($event) {
  }

  async loadTipos() {
    return this.contatoService.tipos().then(tipos => {
      this.tipos = tipos;
      this.tipoTxt();
    });
  }

  tipoTxt() {
    this.tipos.map(item => {
      if (item.id == this.contato.tipo.id) {
        this.tipo_txt = item.descricao;
      }
    });
  }

  async update() {
    this.loader = await this.loadingController.create({});
    await this.loader.present();
    await this.updateContato();
    this.loader.dismiss();
  }

  async updateContato() {
    let params = {
      id: this.contato.id,
      id_morador: this.contato.morador,
      contato: this.contato.contato,
      tipo: this.contato.tipo.id
    };
    
    this.contatoService
      .update(params)
      .then(async result => {
        
        this.dismiss();
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Contato cadastrado com sucesso.",
          buttons: ["OK"]
        });
        alert.present();
      })
      .catch(async errors => {
        
        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"]
        });
        alert.present();
      });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
