import { Injectable } from "@angular/core";
import { FirebaseMessaging } from "@ionic-native/firebase-messaging/ngx";
import { HelpersService } from "./helpers.service";
import { StorageService } from "./storage.service";
import * as firebase from "firebase/app";
import "firebase/messaging";
import { PushService } from "./push.service";
import { StorageMap } from "@ngx-pwa/local-storage";
import { ModalController } from "@ionic/angular";
import { NotificationPage } from "../pages/common/notification/notification.page";
import { Router } from '@angular/router';
import { NotificacoesService } from './notificacoes.service';
import config from "whitelabel/ts/config";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private firebaseWeb;
  private firebaseMessagingWeb;

  constructor(
    private notificacoesService: NotificacoesService,
    private router: Router,
    private modalController: ModalController,
    private storageService: StorageService,
    private pushService: PushService,
    private storage: StorageMap,
    private helpersService: HelpersService,
    private firebaseMessaging: FirebaseMessaging
  ) {}

  notificationReceived() {
    if (this.helpersService.getDeviceType() === "browser") {
      this.firebaseMessagingWeb.onMessage((payload) => {
        //this.modalNotification(payload);
      });
    } else {
      this.firebaseMessaging.onMessage().subscribe(async (payload) => {
        this.modalNotification(payload);
      });
    }
  }

  async reloadNotifications(){
    setTimeout(() => {
      this.notificacoesService.reload();
    }, 300)
  }

  async modalNotification(payload) {
    this.reloadNotifications();
    if(this.canShowModal(payload)){
      const modal = await this.modalController.create({
        component: NotificationPage,
        cssClass: "notification-modal",
        componentProps: { payload },
      });
      await modal.present();
    }
  }

  canShowModal(payload){
    let can = true;
    let usuario_condominio : any = this.storage.get('usuario_condominio');
    if(!usuario_condominio || !usuario_condominio.id_usuario_condominio){
      can = false;
    }
    if(parseInt(payload.tipo) === 4 && this.router.url === '/chat'){
      can = false;
    }
    return can;
  }

  setup() {
    var firebaseConfig = config.firebase_config;

    // Initialize Firebase
    this.firebaseWeb = firebase.initializeApp(firebaseConfig);
    this.firebaseMessagingWeb = this.firebaseWeb.messaging();
  }

  async requestPushPermission() {
    return this.firebaseMessaging
      .requestPermission()
      .then(function () {
      })
      .catch((error) => {

      });
  }

  async registerPushToken() {
    if (this.helpersService.getDeviceType() === "browser") {
      if (!this.firebaseMessagingWeb) {
        this.setup();
      }
      await this.firebaseMessagingWeb
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            this.saveToken(currentToken);
          } else {
          }
        })
        .catch((err) => {
        });
    } else {
      await this.requestPushPermission().then((response) => {
        this.firebaseMessaging.getToken().then((token) => {
          this.saveToken(token);
          return token;
        });
      });
    }
    this.notificationReceived();
  }

  saveToken(token) {
    const usuario_condominio = this.storageService.get("usuario_condominio");
    if (usuario_condominio && usuario_condominio.id_usuario_condominio) {
      this.pushService
        .register(token)
        .then((result) => {
          
        })
        .catch((err) => {
          
        });
    }
    this.storage.set("push_token", token).subscribe({
      next: () => {},
      error: (error) => {
        
      },
    });
  }
}
