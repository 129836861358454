import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MoradorFacialPageRoutingModule } from './morador-facial-routing.module';
import { MoradorFacialPage } from './morador-facial.page';
import { MoradorComponentsModule } from 'src/app/components/morador-components.module';

import { ComponentsModule } from 'src/app/components/components.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MoradorComponentsModule,
    MoradorFacialPageRoutingModule,
    ComponentsModule
  ],
  declarations: [MoradorFacialPage]
})
export class MoradorFacialPageModule { }
