<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <div id="heroCover">
          <ion-avatar>
            <img [src]="avatarPicture">
          </ion-avatar>
          <div class="userName">{{ (user_data.nome ) }}</div>
          <ion-note id="userEmail">{{ user_data.email }}</ion-note>
          <ion-badge id="condominioBadge" color="tertiary">{{ user_data.condominio }}</ion-badge>
          <ion-button *ngIf="usuarios_condominio.length > 1" id="selecionar-condominio" color="secondary" size="small" (click)="modalCondominioSelect()">Condomínios</ion-button>
          <ion-button *ngIf="usuario_condominio.tipo_usuario === 'MORADOR'" id="editar-dados" color="pallete1" size="small" (click)="goPerfil()">Minha Conta</ion-button>
        </div>
        <ion-list id="inbox-list">
          <div style="text-align: center;">
            <ion-spinner name="dots" *ngIf="appPages && appPages.length === 0"></ion-spinner>
          </div>

          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/administracao" lines="none" detail="false" *ngIf="showAdminButton && usuario_condominio.tipo_usuario === 'MORADOR'">
              <ion-icon slot="start" name="cog"></ion-icon>
              <ion-label>Administração</ion-label>
            </ion-item>
            <div *ngFor="let p of appPages; let i = index">
              <ion-item *ngIf="p.enabled" (click)="selectedPage = p.url"  routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="selectedPage == p.id">
                <ion-icon slot="start" [ios]="p.icon" [md]="p.icon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
                <ion-badge color="secondary" *ngIf="p.notificacoes != 0">{{ p.notificacoes }}</ion-badge>
              </ion-item>
            </div>
            <ion-item (click)="logout()"  lines="none" detail="false" ion-button>
              <ion-icon slot="start" [ios]="'log-out-outline'" [md]="'log-out-sharp'"></ion-icon>
              <ion-label>Sair</ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>