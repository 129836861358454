import { Component, OnInit, ViewChild } from "@angular/core";

import {
  Platform,
  ModalController,
  NavController,
  MenuController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "./services/auth.service";
import { StorageService } from "./services/storage.service";
import { Router, Navigation } from "@angular/router";
import { PermissionsService } from "./services/permissions.service";
import { MenuLateralService } from "./services/menu-lateral.service";
import { CondominioTrocaPage } from "./modals/condominio-troca/condominio-troca.page";
import { NotificacoesService } from "./services/notificacoes.service";
import { HelpersService } from "./services/helpers.service";
import { FirebaseService } from "./services/firebase.service";
import { MeuPerfilPage } from "./pages/morador/meu-perfil/meu-perfil.page";
import { MinhacontaService } from "./services/minhaconta.service";
import { environment } from "whitelabel/environments/environment.prod";
import { AppUpdatePage } from "./pages/common/app-update/app-update.page";
import { DomSanitizer } from "@angular/platform-browser";
import { PaginaService } from "./services/paginas.service";
import { CustomizationService } from "./services/customization.service";
import settings from "whitelabel/ts/config";


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  @ViewChild(NavController) navChild: Navigation;
  user_data: any = {
    nome: "",
    email: "",
    condominio: "",
  };
  loginSubscription;
  showAdminButton: Boolean = false;
  public condominio: any = {};
  public usuarios_condominio: any = [];
  public whiteLabelCssEnable: Boolean = false;
  public appPages = [];
  public moradorCustomPages = [];
  public selectedPage = "";
  public usuario_condominio: any = {};
  public masterPages;
  public integradorPages;
  public adminPages;
  public moradorPages;
  public administradorPages;
  public pageList = [];
  public avatarPicture = "assets/img/avatarDefault.png";
  public cPlatform: string = "";
  public loader;
  public whiteLabelCss: string = '';
  constructor(
    private helperService: HelpersService,
    private minhacontaService: MinhacontaService,
    private menuController: MenuController,
    private firebaseService: FirebaseService,
    protected navController: NavController,
    private helpersService: HelpersService,
    private notificacoesService: NotificacoesService,
    private modalController: ModalController,
    private menuLateralService: MenuLateralService,
    private permissionService: PermissionsService,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private paginaService: PaginaService,
    private customizationService: CustomizationService
  ) {
    let page = window.location.href.split("/")[3];
		if (!this.pagesWithoutAuth().includes(page)) {
			this.condominio = this.storageService.get("usuario_condominio").condominio;
			this.showAdminButton = false;

      this.setPageList()

			const dadosUsuario = this.storageService.get("usuario_condominio");

			if (dadosUsuario?.tipo_usuario != 'MASTER') {
				this.menuStringUpdateMorador();
			}

			this.initializeApp();
		} else {
			this.platform.ready().then(async () => {
				this.menuController.enable(false);
			})
		}
  }

  async menuStringUpdateMorador(){
    await this.menuLateralService.moradorPagesUpdate(this.pageList["MORADOR"]);
  }

    ngOnInit() {
    this.selectedPage = window.location.hash.replace("#/", "");
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }

  ionViewWillEnter() {
    this.showAdminButton = false;
    this.user_data.nome = "MORADOR";
    this.user_data.email = "";
    this.avatarPicture = "assets/img/avatarDefault.png";
  }

  toggleMenu() {
    let page = window.location.href.split("/")[3];

    if (this.pagesWithoutAuth().includes(page)) {
      this.menuController.enable(false);
    } else {
      this.menuController.enable(true);
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.firebaseService.registerPushToken();
      this.cPlatform = this.helperService.getDeviceType();
      if (this.platform.is("cordova")) {
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString(settings.main_color);
      }
      let page = window.location.href.split("/")[3];
      if (!this.pagesWithoutAuth().includes(page)) {
        this.toggleMenu();

        let token_type = "";

        if(!settings.onlyBrowserToken) {
          if (this.helpersService.getDeviceType() === "browser") {
            token_type = "browser";
          } else {
            token_type = "app";
          }
        } else {
          token_type = 'browser'
        }

        setTimeout(() => {
          this.storageService.set("token_type", token_type);
        }, 500);

        this.loginSubcriptionSetup();
        this.notificationsCountSetup();
        this.verifyAppVersion();

      }
    });
  }

  async verifyAppVersion() {
    const deviceType = this.helpersService.getDeviceType();
    const remoteAppVersion = await this.helpersService.getAppRemoveVersion();
    const appVersion: any = environment.appVersionNumber;
    if (
      parseInt(remoteAppVersion) > parseInt(appVersion) &&
      deviceType !== "browser"
    ) {
      const modal = await this.modalController.create({
        component: AppUpdatePage,
      });
      return await modal.present();
    }
  }

  async modalCondominioSelect() {
    const modal = await this.modalController.create({
      component: CondominioTrocaPage,
    });
    return await modal.present();
  }

  async loginSubcriptionSetup() {
    let page = window.location.href.split("/")[3];

    return (this.loginSubscription = this.authService.currentUser.subscribe(
      async (data) => {
        this.appPages = [];
        if (data) {
          const token = this.storageService.get("user_token");
          if (!token) {
            if (!this.pagesWithoutAuth().includes(page)) {
              this.router.navigate(["/"], { replaceUrl: true });
            }
            return;
          }
          await this.authService
            .validateToken(token)
            .then(async (data) => {
              this.usuario_condominio = data.usuario_condominio;
              this.whiteLabelCssEnable = true;
              const id_condominio = data.usuario_condominio.id_condominio;
              this.whiteLabelCss = 'https://aut.controlcondo.com.br:8883/whitelabel-styles/'+id_condominio;
              this.menuSetup(data);
            })
            .catch((err) => {
              console.log(err);
              if (!this.pagesWithoutAuth().includes(page)) {
                this.router.navigate(["/"], { replaceUrl: true });
              }
            });
        } else {
          if (!this.pagesWithoutAuth().includes(page)) {
            this.logout();
          } else {
            this.whiteLabelCssEnable = true;
            this.whiteLabelCss = 'https://aut.controlcondo.com.br:8883/whitelabel-styles/-1';
          }
        }
      }
    ));
  }

  pagesWithoutAuth() {
    return [
      "link-liberacao",
      "recuperar-senha",
      "ativar-conta",
      "link-liberacao-recorrente",
      "link-cadastro-titular",
      "liberacao-visitante",
      "link-cadastro-facial",
      "link-cadastro-morador",
      "login"
    ];
  }

  async notificationsCountSetup() {
    this.notificacoesService.reloadData.subscribe(async (data) => {
      this.getNotificationCount();
    });
  }

  async minhaContaProfileSetup() {
    this.minhacontaService.reloadData.subscribe(async (data) => {
      this.avatarPicture = "assets/img/avatarDefault.png";
      this.moradorGetData();
    });
  }

  async goPerfil() {
    const modal = await this.modalController.create({
      component: MeuPerfilPage,
    });
    return await modal.present();
  }

  async logout() {
    await this.authService.logout().catch((err) => {

    });
    this.appPages = [];
    localStorage.clear();
    this.router.navigate(["/"], { replaceUrl: true });
  }


	async menuSetup(data) {

		this.avatarPicture = "assets/img/avatarDefault.png";
		if (!data.usuario || !data.usuario_condominio) {
			window.localStorage.clear();
			this.router.navigate(["loading"], { replaceUrl: true });
			return;
		}
		this.user_data.email = "";
		let condominio = this.storageService.get("usuario_condominio").condominio;
		if (condominio.id_servidor === 2) {
			this.user_data.condominio = "";
			this.user_data.nome = "";
		} else {
			this.user_data.nome = data.usuario.nome;
			this.user_data.email = data.usuario.email;
		}

		this.user_data.email = data.usuario.email;

		this.user_data.condominio = data.usuario_condominio.condominio.nome;
		const { usuario_condominio, permissions } = data;
		this.storageService.set("usuario_condominio", usuario_condominio);
		this.storageService.set("permissions", permissions);

		this.usuarios_condominio = this.storageService.get("usuarios_condominio");
		this.moradorCustomPages = [];

		this.setPageList()

		if (usuario_condominio.situacao >= 1) {
			this.appPages = this.pageList[usuario_condominio.tipo_usuario];
			this.user_data.nome = usuario_condominio.tipo_usuario;
			if (usuario_condominio.tipo_usuario === "MORADOR" && settings.custom_theme) {
				let menusCustomizados = await this.paginaService.getPages();
				this.appPages = this.customizationService.menuPermissionsMorador(menusCustomizados, this.pageList[usuario_condominio.tipo_usuario]);
				this.storageService.set("customization_pages", this.appPages);
			} else if (usuario_condominio.tipo_usuario === "FUNCIONARIO") {
				this.appPages = this.permissionService.menuPermissions(permissions, this.pageList[usuario_condominio.tipo_usuario]);
			} else if (usuario_condominio.tipo_usuario === "MORADOR") {
				this.appPages = this.permissionService.menuPermissionsMorador(permissions, this.pageList[usuario_condominio.tipo_usuario]);
				this.moradorGetData();
				this.minhaContaProfileSetup();
			}

			let hasAdminPermission = false;

			data.grupos_list.map((grupo) => {
				if (grupo.type === 'admin') {
					hasAdminPermission = true;
				}
			});

			if (hasAdminPermission) {
				this.showAdminButton = true;
			} else {
				this.showAdminButton = false;
			}
			this.notificacoesService.reload();
		} else {
			this.logout();
		}
	}

  async moradorGetData() {
    this.minhacontaService.get().then((response) => {
      let condominio = this.storageService.get("usuario_condominio").condominio;

        this.user_data.nome = response.nome;
        if (response.foto && response.foto.foto) {
          this.avatarPicture = response.foto.foto;
        } else {
          this.avatarPicture = "assets/img/avatarDefault.png";
        }
        if (condominio.id_servidor === 2) {
          this.user_data.nome = "MORADOR";
        }

    });
  }



  async getNotificationCount() {
    let usuario_condominio = this.storageService.get("usuario_condominio");
    let pages = this.appPages;
    if (usuario_condominio.tipo_usuario === "MORADOR") {
      this.notificacoesService.countMorador().then((result) => {
        for (let i = 1; i < pages.length; i++) {
          pages[i].notificacoes = result[pages[i].id];
        }
        this.appPages = pages;
      });
    }
  }

	setPageList() {

		let condominio = this.storageService.get("usuario_condominio").condominio;

		this.pageList["FUNCIONARIO"] = this.menuLateralService.funcionarioPages();
		this.pageList["MASTER"] = this.menuLateralService.masterPages();
		this.pageList["MORADOR"] = this.menuLateralService.moradorPages();
		this.pageList["ADMINISTRADOR"] = this.menuLateralService.administradorPages(condominio);
	}
}
