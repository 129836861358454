import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {

	constructor(
		private storageService: StorageService
	) { }

	menuPermissions(permissions, menu) {

		if (permissions.config_comunicados >= 1) menu = this.changeMenuItem(menu, 'comunicados-adm');

		if (permissions.config_moradores >= 1) menu = this.changeMenuItem(menu, 'moradores-adm');

		if (permissions.config_funcionarios >= 1) menu = this.changeMenuItem(menu, 'funcionarios-adm');

		if (permissions.auditoria >= 1) menu = this.changeMenuItem(menu, 'auditoria');

		if (permissions.config_documentos >= 1) menu = this.changeMenuItem(menu, 'documentos-adm');

		if (permissions.config_mudanca >= 1) menu = this.changeMenuItem(menu, 'mudanca-adm');

		if (permissions.config_unidades >= 1) menu = this.changeMenuItem(menu, 'unidades-adm');

		if (permissions.config_visita_motivo >= 1) menu = this.changeMenuItem(menu, 'motivos-de-visita');

		if (permissions.config_reservas >= 1) menu = this.changeMenuItem(menu, 'areas-comuns-adm');

		if (permissions.config_ocorrencia >= 1) menu = this.changeMenuItem(menu, 'ocorrencias-adm');

		if (permissions.config_enquete >= 1) menu = this.changeMenuItem(menu, 'enquetes-adm');

		if (permissions.config_pet >= 1) {
			menu = this.changeMenuItem(menu, 'pets-adm');
			menu = this.changeMenuItem(menu, 'especies-adm');
		}

		if (permissions.config_fornecedores >= 1) menu = this.changeMenuItem(menu, 'fornecedores-adm');

		if (permissions.config_grupos >= 1) menu = this.changeMenuItem(menu, 'grupos-adm');

		if (permissions.config_correspondencia_adm >= 1) menu = this.changeMenuItem(menu, 'correspondencia-adm');

		if (permissions.config_assembleias_adm >= 1) menu = this.changeMenuItem(menu, 'assembleias-adm');

		if (permissions.config_mudanca >= 1) menu = this.changeMenuItem(menu, 'mudancas-adm');

		if (permissions.config_atendimento >= 1) menu = this.changeMenuItem(menu, 'atendimento-adm');

		if (permissions.config_saida_materais >= 1) menu = this.changeMenuItem(menu, 'saida-materiais-adm');

		if (permissions.config_mobile_device >= 1) menu = this.changeMenuItem(menu, 'leitoresmobile-adm');

		if (permissions.config_tabela_horarios >= 1) menu = this.changeMenuItem(menu, 'tabela-horarios-adm');

		return menu;
	}

	menuPermissionsMorador(permissions, menu) {
		if (permissions.morador_atendimento >= 1) {
			menu = this.changeMenuItem(menu, 'atendimento');
		}
		if (permissions.morador_assembleias >= 1) {
			menu = this.changeMenuItem(menu, 'assembleias');
		}
		if (permissions.morador_comunicados >= 1) {
			menu = this.changeMenuItem(menu, 'comunicados');
		}
		if (permissions.morador_documentos >= 1) {
			menu = this.changeMenuItem(menu, 'documentos');
		}
		if (permissions.morador_enquete >= 1) {
			menu = this.changeMenuItem(menu, 'enquetes');
		}
		if (permissions.morador_reserva >= 1) {
			menu = this.changeMenuItem(menu, 'areas_comuns');
		}
		if (permissions.morador_camera >= 1) {
			menu = this.changeMenuItem(menu, 'cameras');
		}
		if (permissions.morador_chat >= 1) {
			menu = this.changeMenuItem(menu, 'chat');
		}
		if (permissions.morador_entregas >= 1) {
			menu = this.changeMenuItem(menu, 'entregas');
		}
		if (permissions.morador_liberacao_unica >= 1 || permissions.morador_liberacao_recorrente >= 1) {
			menu = this.changeMenuItem(menu, 'visitas');
		}
		if (permissions.morador_pet >= 1) {
			menu = this.changeMenuItem(menu, 'pets');
		}
		if (permissions.morador_ocorrencia >= 1) {
			menu = this.changeMenuItem(menu, 'ocorrencias');
		}
		if (permissions.morador_mudanca >= 1) {
			menu = this.changeMenuItem(menu, 'mudancas');
		}
		if (permissions.morador_saida_materais >= 1) {
			menu = this.changeMenuItem(menu, 'saida_materiais');
		}
		return menu;
	}

	changeMenuItem(menu, key, enabled = true) {
		const elementsIndex = menu.findIndex(element => element.id === key);
		let newArray = [...menu];
		newArray[elementsIndex] = { ...newArray[elementsIndex], enabled };
		return newArray;
	}

	protectAdminPage(page) {
		return true;
	}

	handleMasterPage() {
		const usuario_condominio = this.storageService.get('usuario_condominio');
		if (usuario_condominio.tipo_usuario !== 'MASTER') {
			localStorage.clear();
			window.location.reload();
		}
	}
}
