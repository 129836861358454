import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiRequestService } from './api-request.service';
import { HelpersService } from './helpers.service';
import { Device } from '@ionic-native/device/ngx';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private deviceService: DeviceDetectorService,
    private device: Device,
    private helperService: HelpersService,
    private apiRequest: ApiRequestService,
  ) { }

  public register = async push_token => {
    let params : any = { id_notificacao : push_token };
    if(this.helperService.getDeviceType() !== 'browser'){
      params.platform = this.device.platform;
      params.model = this.device.model;
      params.uuid = this.device.uuid;
      params.version = this.device.version;
    } else {
      params.platform = 'browser';
      params.model = this.deviceService.getDeviceInfo().browser + ' | '+this.deviceService.getDeviceInfo().os_version;
      params.uuid = uuidv4();
      params.version = this.deviceService.getDeviceInfo().browser_version;
    }
    
    return this.apiRequest.apiAut("push/register", params, "POST").then((response) => {
      return response.data;
    });
  }
}
