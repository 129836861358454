import { async } from '@angular/core/testing';
import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
  providedIn: "root",
})
export class AcionadoresService {
  constructor(private apiRequest: ApiRequestService) {}

  public adminCreate = async (params) => {
    return this.apiRequest
      .apiServer("admin/acionadores", params, "POST")
      .then((response) => {
        return response.data;
      });
  };
  public update = async (id, params) => {
    return this.apiRequest
      .apiServer("liberacao/update/"+id, params, "PUT")
      .then((response) => {
        return response.data;
      }).catch((response) => {
        throw response.data;
      });
  };

  public adminReplace = async (id) => {
    return this.apiRequest
      .apiServer("admin/acionadores/replace/"+id, {}, "POST")
      .then((response) => {
        return response.data;
      });
  };
  public getacionador = async (id, params) => {
    return this.apiRequest
    .apiServer("face/" + id, params)
    .then((response) => {
      return response.data;
    });
  }
  public get = async (id, params) => {
    return this.apiRequest
      .apiServer("acionadores/" + id, params)
      .then((response) => {
        return response.data;
      });
  };
}
