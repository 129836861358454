import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { MinhacontaService } from 'src/app/services/minhaconta.service';
import { VeiculosService } from 'src/app/services/veiculos.service';
import { MeusVeiculosCreatePage } from '../meus-veiculos-create/meus-veiculos-create.page';
import { MeusVeiculosEditPage } from '../meus-veiculos-edit/meus-veiculos-edit.page';

@Component({
  selector: 'app-meus-veiculos',
  templateUrl: './meus-veiculos.page.html',
  styleUrls: ['./meus-veiculos.page.scss'],
})
export class MeusVeiculosPage implements OnInit {

  veiculos : any = [];
  veiculosSkeleton : any = new Array<any>(10);
  canLoadMore: Boolean = true;
  veiculosLoaded : Boolean = false;
  firstLoad : Boolean = true;
  params: any = {
    offset: 0, limit: 10
  };
  editVeiculos: Boolean = false;
  constructor(
    private minhacontaService: MinhacontaService,
    private veiculosService: VeiculosService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loadPermissions();
  }

  async loadPermissions() {
    await this.minhacontaService
      .permissions()
      .then(async (response) => {
        const { data } = response;
        this.editVeiculos = data.morador_veiculos === 1 ? true : false;
      })
      .catch((err) => {});
  }

  async ionViewWillEnter() {
    await this.doRefresh();
  }

  async doRefresh(event?) {
    this.veiculosLoaded = false;
    this.firstLoad = true;
    this.veiculos = [];
    await this.loadData();
    event && event.target.complete();
  }

  async modalEditVeiculo(veiculo) {
    if(!this.editVeiculos){
      return;
    }
    const modal = await this.modalController.create({
      component: MeusVeiculosEditPage,
      componentProps: { veiculo }
    });
    await modal.present();

    const response = await modal.onWillDismiss();
    if (response) {
      this.doRefresh();
    }
  }

  async loadData(eventInfinite?){
   this.params.offset  = this.veiculos.length;
   await this.veiculosService.list(this.params).then((response) => {
     
      this.canLoadMore = response.length === 0 ? false : true;
      this.veiculos = [...this.veiculos, ...response];
      this.veiculosLoaded = true;
      this.firstLoad = false;
    }).catch((err) => {
    });

    eventInfinite && eventInfinite.target.complete();
  }

  async modalCreateVeiculo() {
    const modal = await this.modalController.create({
      component: MeusVeiculosCreatePage,
    });
    await modal.present();

    const response = await modal.onWillDismiss();
    if (response) {
      this.doRefresh();
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }

  getFoto(veiculo){
    if(veiculo && veiculo.foto && veiculo.foto.foto){
      return veiculo.foto.foto;
    } else {
      return 'assets/img/car-placeholder.jpg';
    }
  }

}
