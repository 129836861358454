import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
	providedIn: "root"
})
export class MoradoresService {
	constructor(private apiRequest: ApiRequestService) { }

	public list = async (params = {}) => {
		return this.apiRequest.apiServer("moradores", params).then(response => {
			return response.data;
		});
	};
	
	async updateFoto(id, params) {
		params.uploadFile = true;
		return this.apiRequest
			.apiServer("admin/moradores/foto/"+id, params, "POST")
			.then((response) => {
				console.log(response)
				return response.data;
			})
			.catch((response) => {
				console.log(response)
				throw response.data;
			});
	}

	async atualizaFacial(params, token) {
		return await this.apiRequest
			.apiServer("link-cadastro-facial/updatePerfil/" + token, params, "POST")
			.then((response) => {

				return response.data;
			})
			.catch((response) => {
				throw response.data;
			});
	}

	updateVisitante(id, params) {
		params.uploadFile = true;
		return this.apiRequest
		  .apiServer("link_liberacao/atualizaVisitante/" + id, params, "POST")
		  .then((response) => {
			return response.data;
		  })
		  .catch((response) => {
			throw response.data;
		  });
	  }

	public adminList = async (params = {}) => {
		return this.apiRequest
			.apiServer("admin/moradores", params)
			.then(response => {
				return response.data;
			});
	};

	public adminGet = async id => {
		return this.apiRequest.apiServer("admin/moradores/" + id).then(response => {
			return response.data;
		});
	};
	
	public noAuthSave = async (params: any = {}) => {
		return this.apiRequest.apiServer("link_cadastro_titular/save", params, "POST").then((response) => {
			return response.data;
		});
	};
	
	public noAuthUpdate = async (params, token) => {
		return this.apiRequest.apiServer("link-cadastro-facial/save/" + token, params, "POST").then((response) => {
			return response.data;
		});
	};
	
	public adminSave = async params => {
		return this.apiRequest
			.apiServer("admin/moradores", params, "POST")
			.then(response => {
				return response.data;
			}).catch((response) => {
				throw response.data;
			});
	};

	public adminUpdate = async params => {
		return this.apiRequest
			.apiServer("admin/moradores/" + params.id, params, "PUT")
			.then(response => {
				return response.data;
			}).catch((response) => {
				throw response.data;
			});
	};

	public adminDelete = async id => {
		return this.apiRequest
			.apiServer("admin/moradores/" + id, {}, "DELETE")
			.then(response => {
				return response.data;
			});
	};

	public adminRestore = async id => {
		return this.apiRequest
			.apiServer("admin/moradores/" + id + "/restore", {}, "POST")
			.then(response => {
				return response.data;
			});
	};
}
