import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MeusContatosPageRoutingModule } from './meus-contatos-routing.module';
import { MeusContatosPage } from './meus-contatos.page';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MeusContatosCreatePageModule } from '../meus-contatos-create/meus-contatos-create.module';
import { MeusContatosEditPageModule } from '../meus-contatos-edit/meus-contatos-edit.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    MeusContatosPageRoutingModule,
    MeusContatosCreatePageModule,
    MeusContatosEditPageModule
  ],
  declarations: [MeusContatosPage]
})
export class MeusContatosPageModule {}
