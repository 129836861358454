import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucFirstLetter'
})
export class UcFirstLetterPipe implements PipeTransform {

  transform(value: String, ...args: unknown[]): unknown {
    return this.titleCase(value);
  }

  titleCase(str) {
    var splitStr = str.toLowerCase().trim().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      if(i === 0){
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
        
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

}
