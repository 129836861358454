import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';

@Injectable({
	providedIn: 'root'
})
export class LiberacoesUnicasService {
	constructor(
		private apiRequest: ApiRequestService,
	) { }

	public invalidar = async id => {
		return this.apiRequest.apiServer("liberacoes-unicas-invalidar/" + id).then((response) => {
			return response.data;
		});
	}

	public qrcode = async code => {
		return this.apiRequest.apiServer("liberacoes-unicas/qrcode/img/" + code).then((response) => {
			return response.data;
		});
	};

	public qrcodeFull = async params => {
		return this.apiRequest.apiServer("liberacoes-unicas/qrcode/full", params).then((response) => {
			return response.data;
		});
	};

	public getImagemBiometria = async id => {
		return this.apiRequest.apiServer("getImagemBiometria/" + id).then((response) => {
			return response.data;
		});
	}

	public createSenha = async params => {
		return this.apiRequest.apiServer("liberacoes-unicas/create-senha/save", params, "POST").then((response) => {
			return response.data;
		});
	}

	public verifyLiberacaoLink = async (id, params) => {
		return this.apiRequest.apiServer("verifyLiberacaoLink/" + id, params).then((response) => {
			return response.data;
		});
	};

	updateVisitante(id, params) {
		return this.apiRequest.apiServer("updateVisitante/" + id, params, 'PUT').then((response) => {
			return response.data;
		}).catch((response) => {
			throw response.data;
		})
	}

	public enviarEmail = async params => {
		return this.apiRequest.apiServer("liberacoes-unicas/qrcode/enviarEmail", params).then((response) => {
			return response.data;
		});
	};

	public list = async params => {
		return this.apiRequest.apiServer("liberacoes-unicas", params).then((response) => {
			return response.data;
		});
	};

	public save = async params => {
		return this.apiRequest.apiServer("liberacoes-unicas", params, "POST").then((response) => {
			return response.data;
		}).catch((response) => {
			throw response.data;
		})
	};

	public update = async (id, params) => {
		return this.apiRequest.apiServer("liberacoes-unicas/" + id, params, "PUT").then((response) => {
			return response.data;
		});
	};

	public fixSFA = async (id, params) => {
		return this.apiRequest.apiServer("liberacoes-unicas-fixsfa/" + id, params).then((response) => {
			return response.data;
		});
	}

	public fixSFARecorrente = async (id, params) => {
		return this.apiRequest.apiServer("liberacoes-recorrente-fixsfa/" + id, params).then((response) => {
			return response.data;
		});
	}
}
