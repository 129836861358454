import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
  providedIn: "root",
})
export class NotificacaoBloqueiosService {
  constructor(private apiRequest: ApiRequestService) {}
  public list = async (params = {}) => {
    return this.apiRequest.apiServer("notificacoes/bloqueios", params).then((response) => {
      return response.data;
    });
  };
  public save = async (params = {}) => {
    return this.apiRequest.apiServer("notificacoes/bloqueios", params, "POST").then((response) => {
      return response.data;
    });
  };
  public delete = async (params = {}) => {
    return this.apiRequest.apiServer("notificacoes/bloqueios", params, "DELETE").then((response) => {
      return response.data;
    });
  };
}
