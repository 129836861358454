<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Editar familiar</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="morador.id">
    <ion-row>
      <ion-col size="12">
        <div class="header">
          <img [src]="userPicture" id="coverAvatar" />
          <app-upload-button
            title="Enviar foto"
            color="primary"
            [fileTypes]="['jpeg', 'jpg', 'png', 'gif']"
            (onPictureSelected)="responseAvatar($event)"
          ></app-upload-button>
        </div>
      </ion-col>
      <ion-col size="12" sizeMd="4">
        <ion-item>
          <ion-label position="floating">Nome</ion-label>
          <ion-input
            name="nome"
            type="text"
            [ngModel]="morador.nome"
            (ngModelChange)="morador.nome=$event"
            required
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="4">
        <ion-item>
          <ion-label position="floating">RG</ion-label>
          <ion-input
            name="rg"
            type="text"
            [(ngModel)]="morador.RG"
            required
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="4">
        <ion-item>
          <ion-label position="floating">CPF</ion-label>
          <ion-input
            name="CPF"
            type="text"
            [ngModel]="morador.telefone | cpfMask"
            (ngModelChange)="morador.telefone=$event"
            maxlength="14"
            required
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item style="margin-top: -3px">
          <ion-label position="floating">Data Nascimento</ion-label>
          <ion-datetime
            cancelText="Cancelar"
            doneText="Selecionar"
            displayFormat="DD/MM/YYYY"
            value=""
            [(ngModel)]="morador.nascimento_raw"
            required
          ></ion-datetime>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item style="margin-top: 7px;">
          <ion-label>Parentesco</ion-label>
          <ion-select
            [(ngModel)]="morador.parentesco.id"
            okText="Selecionar"
            placeholder="Selecione"
            cancelText="Cancelar"
          >
            <ion-select-option
              *ngFor="let parentesco of parentescos"
              [value]="parentesco.id"
              >{{ parentesco.descricao | ucFirstLetter }}</ion-select-option
            >
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item>
          <ion-label>Sexo</ion-label>
          <ion-select
            [(ngModel)]="morador.masculino"
            value=""
            okText="Selecionar"
            placeholder="Selecione"
            cancelText="Cancelar"
          >
            <ion-select-option [value]="1">Masculino</ion-select-option>
            <ion-select-option [value]="0">Feminino</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6" [hidden]="true">
        <ion-item style="margin-top: 1px">
          <ion-label>Control Condo</ion-label>
          <ion-toggle [(ngModel)]="morador.usar_control_condo"></ion-toggle>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="12" *ngIf="morador.usar_control_condo" [hidden]="true">
        <ion-item *ngIf="morador.usar_control_condo">
          <ion-label position="floating">E-mail</ion-label>
          <ion-input
            name="email"
            type="email"
            [(ngModel)]="morador.email"
            required
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer>
  <ion-button color="success" expand="block"  (click)="save()">
    <ion-icon name="checkmark-outline" slot="start"></ion-icon>
    Atualizar familiar
  </ion-button>
</ion-footer>
