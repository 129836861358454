import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { SelectSearchComponent } from "./select-search/select-search.component";
import { PipesModule } from "../pipes/pipes.module";
import { VisitaUnicaComponent } from "./visita-unica/visita-unica.component";
import { VisitaRecorrenteComponent } from "./visita-recorrente/visita-recorrente.component";
import { ProgressComponent } from "./progress/progress.component";
import { UploaderMultifileComponent } from "./uploader-multifile/uploader-multifile.component";
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { ColorSelectorComponent } from "./color-selector/color-selector.component";



import { VeiculoListComponent } from './veiculo/veiculo-list/veiculo-list.component';
import { ContatoListComponent } from './contato/contato-list/contato-list.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { EmptyBoxComponent } from './empty-box/empty-box.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { UploadSingleBoxComponent } from './upload-single-box/upload-single-box.component';
import { UploadAvatarComponent } from "./upload-avatar/upload-avatar.component";
import { MoradorFormularioComponent } from "./morador/morador-formulario/morador-formulario.component";
import {OrdenacaoAreasComponent} from '../components/area-comum/ordenacao-areas/ordenacao-areas.component';
// import { AvatarSelectorComponent } from "./avatar-selector/avatar-selector.component";

@NgModule({
  declarations: [
    ProgressComponent,
    EmptyBoxComponent,
    VeiculoListComponent,
    VisitaUnicaComponent,
    UploadButtonComponent,
    VisitaRecorrenteComponent,
    SelectSearchComponent,
    ContatoListComponent,
    UploaderMultifileComponent,
    HeaderLogoComponent,
    ExpandableComponent,
    UploadSingleBoxComponent,
    UploadAvatarComponent,
    ColorSelectorComponent,
    MoradorFormularioComponent,
    OrdenacaoAreasComponent
  ],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [
    EmptyBoxComponent,
    VisitaUnicaComponent,
    NgxDocViewerModule,
    VeiculoListComponent,
    ContatoListComponent,
    VisitaRecorrenteComponent,
    UploadButtonComponent,
    SelectSearchComponent,
    HeaderLogoComponent,
    UploaderMultifileComponent,
    ProgressComponent,
    ExpandableComponent,
    UploadSingleBoxComponent,
    UploadAvatarComponent,
    ColorSelectorComponent,
    MoradorFormularioComponent,
    OrdenacaoAreasComponent
  ]
})
export class ComponentsModule {}
