import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageTranslatorService {

  strings = {
    user_token: '362744762f9fa08cf20d225f487361ab',
    usuario_condominio: '0d262744cf22f25f487367639fa081ab',
    permissions: '62cf281742f7625f4873460d239fa0ab',
    usuarios_condominio: '744cf2239fa081ab0d2f25f487367662',
    token_type: '39fa0f25f48722ab081f3744cd267662',
    push_token: '2676480f2cf31fa5f7dab08397442262',
    menu_funcionario: 'd2f2539fa081ab067f48744cf2273662',
    customization_pages: 'd2f2539fa081ab027f48744cf2273663',
    root_categories: 'd2f2539f12081ab027f48744cf2273663',
  };

  constructor() { }

  translate(string){
    return this.strings[string];
  }
}
