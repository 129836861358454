import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoaderControllerService {
  loader;
  constructor(private loadingController: LoadingController) {}

  async present(message) {
    this.loader = await this.loadingController.create({ message });
    this.loader?.present();
  }

  async dismiss() {
    this.loader?.dismiss();
  }
}
