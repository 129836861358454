import { Component, Input, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { HelpersService } from "src/app/services/helpers.service";
import { Camera } from "@ionic-native/camera/ngx";
import { File, FileEntry } from "@ionic-native/file/ngx";
import { StorageService } from "src/app/services/storage.service";
import { MoradoresService } from "src/app/services/moradores.service";
import { AcionadoresService } from "src/app/services/acionadores.service";
import Axios from "axios";
import { LiberacoesUnicasService } from "src/app/services/liberacoes-unicas.service";

@Component({
  selector: "app-morador-facial",
  templateUrl: "./morador-facial.page.html",
  styleUrls: ["./morador-facial.page.scss"],
})
export class MoradorFacialPage implements OnInit {
  @Input("morador") morador: any;
  deviceType: any = "";
  loader;
  formDataFilled: any;
  condominio: any;
  acionador_facial: any;
  constructor(
    private acionadoresService: AcionadoresService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private file: File,
    private camera: Camera,
    private helpersService: HelpersService,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private moradoresService: MoradoresService,
    private liberacoesUnicasService: LiberacoesUnicasService
  ) { }

  ngOnInit() { }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  loadFacial() {
    if (this.condominio.id_servidor === 2) return;

    this.liberacoesUnicasService.getImagemBiometria(this.acionador_facial.id).then((result) => {

    }).catch((err) => {

    })
  }

  ionViewWillEnter() {
    if (this.morador.length === 0 || !this.morador.id) {
      return this.dismiss();
    }
    this.condominio = this.storageService.get("usuario_condominio").condominio;
    this.deviceType = this.helpersService.getDeviceType();
    this.loadMorador();
  }

  async loadMorador() {
    await this.moradoresService.adminGet(this.morador.id).then((data) => {
      if(data.acionador_facial) {
        this.acionador_facial = data.acionador_facial;
        this.loadFacial();
      }
    });
  }

  async validatorFaceError(msg) {
    const errorCode = parseInt(msg);
    let message = "Erro desconhecido.";
    if (errorCode === 1) {
      message = "Nenhum rosto encontrado na foto.";
    }
    if (errorCode === 2) {
      message = "Máximo de 1 rosto por vez.";
    }
    if (errorCode === 3) {
      message = "Peso máximo da foto 200kb.";
    }
    if (errorCode === 4) {
      message = "Foto com baixa iluminação.";
    }
    if (errorCode === 5) {
      message = "Tamanho mínimo 400px de cada lado.";
    }
    if (errorCode === 6) {
      message = "Tamanho máximo 4096px de cada lado.";
    }
    if (errorCode === 7) {
      message = "Rosto muito próximo da camera.";
    }

    const alert = await this.alertController.create({
      header: "Erro ao validar foto",
      message: message,
      buttons: ["OK"],
    });
    alert.present();
  }

  async facialPictureSelected($event) {
    let imageData = "";
    if ($event.formData != null) {
      imageData = $event.formData;
    } else if ($event.base64 != null) {
      imageData = $event.base64;
    } else {
      return false;
    }

    this.loader = await this.loadingController.create({
      message: "Validando facial",
    });
    this.formDataFilled = $event.formData;
    this.finish();
  }

  async finish() {
    this.loader = await this.loadingController.create({
      message: "Cadastrando facial...",
    });
    await this.loader.present();

    const token_type = this.storageService.get("token_type");

    let formData = this.formDataFilled;
    formData.append("morador", this.morador.id);
    formData.append("visitante", -1);
    formData.append("id_condominio", this.condominio.id_condominio);
    formData.append("token_type", token_type);

    await this.moradoresService
      .updateFoto(this.morador.id, formData)
      .then(async (response) => {

        this.modalCtrl.dismiss();
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "A foto do facial foi cadastrada corretamente.",
          buttons: ["OK"],
        });
        alert.present();
      })
      .catch(async (err) => {
        
        let errMsg = "Tente novamente mais tarde."

        if(err.msg) errMsg = err.msg
        console.log(err)
        console.log(errMsg)
        const alert = await this.alertController.create({
          header: "Erro no envio",
          message: errMsg,
          buttons: ["OK"],
        });
        alert.present();
      })
      .finally(() => {
        this.loader.dismiss();
      });

      this.loader.dismiss();
  }
}
