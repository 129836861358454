import { NgModule } from "@angular/core";
import { DateTimeToStringPipe } from "./date-time-to-string.pipe";
import { UcFirstLetterPipe } from "./uc-first-letter.pipe";
import { CpfMaskPipe } from "./cpf-mask.pipe";
import { rgValidatorPipe } from "./rg-validator.pipe";
import { CnpjValidatorPipe } from "./cnpj-validator.pipe";
import { PhoneValidatorPipe } from "./phone-validator.pipe";
import { CepValidatorPipe } from './cep-validator.pipe';
import { TimestampToStringPipe } from './timestamp-to-string.pipe';
import { LicensePlatePipe } from './license-plate.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { MoneyValidatorPipe } from './money-validator.pipe';
import { ReversePipe } from './reverse.pipe';
import { StrToUpperPipe } from './str-to-upper.pipe';
import { AlphaNumericPipe } from './alpha-numeric.pipe';
import { HiddenFieldPipe } from './hidden-field.pipe';
import { MenorIdadePipe } from "./menor-idade.pipe";

@NgModule({
  declarations: [
    DateTimeToStringPipe,
    LicensePlatePipe,
    UcFirstLetterPipe,
    CpfMaskPipe,
    rgValidatorPipe,
    CnpjValidatorPipe,
    TitleCasePipe,
    PhoneValidatorPipe,
    CepValidatorPipe,
    TimestampToStringPipe,
    LicensePlatePipe,
    TitleCasePipe,
    MoneyValidatorPipe,
    ReversePipe,
    StrToUpperPipe,
    AlphaNumericPipe,
    HiddenFieldPipe,
    MenorIdadePipe
  ],
  imports: [],
  exports: [
    DateTimeToStringPipe,
    TitleCasePipe,
    LicensePlatePipe,
    UcFirstLetterPipe,
    CpfMaskPipe,
    rgValidatorPipe,
    CnpjValidatorPipe,
    PhoneValidatorPipe,
    CepValidatorPipe,
    MoneyValidatorPipe,
    ReversePipe,
    StrToUpperPipe,
    AlphaNumericPipe,
    HiddenFieldPipe,
    MenorIdadePipe
  ],
  providers: [
    CpfMaskPipe,
    MenorIdadePipe
  ]
})
export class PipesModule {}
