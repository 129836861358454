import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
  providedIn: "root"
})
export class VeiculosService {
  constructor(private apiRequest: ApiRequestService) {}

  public list = async params => {
    return this.apiRequest.apiServer("veiculos", params).then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public save = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos", params, "POST").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public update = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos/"+params.id, params, "PUT").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminDelete = async id => {
    return this.apiRequest.apiServer("admin/veiculos/"+id, {}, "DELETE").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminSave = async (params : any = {}) => {
    return this.apiRequest.apiServer("admin/veiculos", params, "POST").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public adminUpdate = async (params : any = {}) => {
    return this.apiRequest.apiServer("admin/veiculos/"+params.id, params, "PUT").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public marcas = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos/marcas", params).then((response) => {
      return response.data;
    });
  };
  public noAuthMarcas = async (params) => {
    return this.apiRequest.apiServer("link_cadastro_titular/veiculos/marcas", params).then((response) => {
      return response.data;
    });
  };

  public modelos = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos/modelos", params).then((response) => {
      return response.data;
    });
  };
  public noAuthmodelos = async (params) => {
    return this.apiRequest.apiServer("link_cadastro_titular/veiculos/modelos", params).then((response) => {
      return response.data;
    });
  };

  public cores = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos/cores", params).then((response) => {
      return response.data;
    });
  };
  public noAuthCores = async (params : any = {}) => {
    return this.apiRequest.apiServer("link_cadastro_titular/veiculos/cores", params).then((response) => {
      return response.data;
    });
  };


  public getByPlaca = async placa => {
    return this.apiRequest.apiServer("veiculos/getByPlaca/"+placa, {placa}).then((response) => {
      return response.data;
    });
  };

  public saveVisitante = async (params : any = {}) => {
    return this.apiRequest.apiServer("veiculos/saveVisitante", params, "POST").then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  };

  public historico = async (params?) => {
    return this.apiRequest.apiServer("veiculos/historico", params).then((response) => {
      return response.data;
    }).catch((response) => {
      throw response.data;
    });
  }
}
