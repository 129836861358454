<div id="holder">
  <div class="content">
    <div><ion-img [src]="image" *ngIf="image"></ion-img></div>
    <div class="top">
      <h1>{{ title }}</h1>
      <h2>{{ msg }}</h2>
    </div>
  </div>
  <div class="buttons">
    <ion-button color="secondary" (click)="redirect()" *ngIf="btn1">
      <ion-icon [name]="icon" slot="start" *ngIf="icon"></ion-icon>
      {{ btn1 }}
    </ion-button>
    <ion-button color="medium"  (click)="dismiss()" *ngIf="btn2">
      {{ btn2 }}
    </ion-button>
  </div>
</div>
