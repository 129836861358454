<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Morador - Cadastro Facial</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="content-box">
    <div *ngIf="condominio && morador.face != null">
      <!-- condominio.server.http  -->
      <app-avatar-selector [preview]="condominio.server.http+':5002/'+morador.face['url']"
        (onPictureSelected)="facialPictureSelected($event)">
      </app-avatar-selector>
    </div>
    <div *ngIf="condominio && morador.face == null">
      <!-- condominio.server.http  -->
      <app-avatar-selector (onPictureSelected)="facialPictureSelected($event)">
      </app-avatar-selector>
    </div>

    <p>
      Posicione o dispositivo na altura do rosto.<br>
      Utilize como fundo uma parede sem elementos.
    </p>
  </div>

</ion-content>