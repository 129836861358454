import { Pipe, PipeTransform } from '@angular/core';
import moment, { MomentInput } from 'moment';

@Pipe({
	name: 'menorIdade'
})
export class MenorIdadePipe implements PipeTransform {

	transform(idade: MomentInput): boolean {
		if (moment().diff(moment(idade).format("YYYY-MM-DD"), 'year', true) < 18) {
			return true
		}
		return false
	}
}
