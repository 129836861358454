<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Meus Veículos</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed" pullFactor="0.5" pullMin="50" pullMax="200" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content
      pullingIcon="chevron-down-circle-outline"
      refreshingSpinner="circles" >
    </ion-refresher-content>
  </ion-refresher>

    <ion-item *ngIf="params.offset === 0 && veiculos.length === 0 && !firstLoad">Nenhum veículo encontrado</ion-item>
  
    <ion-item *ngFor="let veiculo of veiculos" class="veiculo-item" lines="full">
      <div>
        <div>
          <ion-avatar>
            <ion-img src="{{ getFoto(veiculo) }}"></ion-img>
          </ion-avatar>
          <div>
            <ion-badge color="pallete1" *ngIf="veiculo.modelo && veiculo.modelo.marca">
              {{ veiculo.modelo.marca.marca }} {{ veiculo.modelo.modelo }} {{ veiculo.cor.cor }}
            </ion-badge>
        
            <div class="placa">
              <div>{{ veiculo.placa.replace('-', '') }}</div>
            </div>
          </div>
        </div>
  
        <div *ngIf="editVeiculos">
          <ion-button color="pallete2" (click)="modalEditVeiculo(veiculo)" class="edit">
            <ion-icon slot="icon-only" name="create-outline"></ion-icon>
          </ion-button>
        </div>
      </div>
    </ion-item>

  <div *ngIf="!veiculosLoaded">
    <ion-item *ngFor="let c of veiculosSkeleton">
      <ion-skeleton-text animated style="width: 27px; height: 27px" slot="start"></ion-skeleton-text>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </div>
  
  
  <ion-infinite-scroll *ngIf="canLoadMore" threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <ion-fab style="position: fixed;" *ngIf="editVeiculos" vertical="bottom" horizontal="end" slot="fixed" (click)="modalCreateVeiculo()">
    <ion-fab-button color="success">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
