import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Contato } from 'src/app/entities/contato';
import { ContatosService } from 'src/app/services/contatos.service';

@Component({
  selector: 'app-meus-contatos-create',
  templateUrl: './meus-contatos-create.page.html',
  styleUrls: ['./meus-contatos-create.page.scss'],
})
export class MeusContatosCreatePage implements OnInit {
  contato: Contato = new Contato();
  tipos: any[] = [];
  loader;
  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private contatoService: ContatosService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.setup();
  }

  async setup() {
    this.loader = await this.loadingController.create({});
    this.loader.present();
    await this.loadTipos();
    this.loader.dismiss();
  }

  async loadTipos() {
    return this.contatoService.tipos().then(tipos => {
      this.tipos = tipos;
    });
  }

  async save() {
    this.loader = await this.loadingController.create({});
    await this.loader.present();
    await this.saveContato();
    this.loader.dismiss();
  }


  async saveContato() {
    let params = {
      contato: this.contato.contato,
      tipo: this.contato.tipo
    };
    
    this.contatoService
      .save(params)
      .then(async (result) => {
        
        this.dismiss();
        const alert = await this.alertController.create({
          header: "Sucesso",
          message: "Contato cadastrado com sucesso.",
          buttons: ["OK"]
        });
        alert.present();
      })
      .catch(async errors => {
        
        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"]
        });
        alert.present();
      });
  }


  dismiss(){
    this.modalController.dismiss();
  }

}
