<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Editar veículo</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-col size="12">
    <div><img [src]="userPicture" id="coverAvatar" /></div>

    <div class="ion-text-center">
      <app-upload-button
        title="Enviar foto"
        color="primary"
        [fileTypes]="['jpeg', 'jpg', 'png']"
        (onPictureSelected)="responseAvatar($event)"
      >
      </app-upload-button>
    </div>
  </ion-col>
  <ion-item lines="full" *ngIf="showLpr">
    <ion-label>LPR</ion-label>
    <ion-checkbox slot="end" [(ngModel)]="lpr"></ion-checkbox>
  </ion-item>
  <ion-item>
    <ion-label>Marca</ion-label>
    <ion-select
      (ionChange)="loadModelos();marcaTxt()"
      [selectedText]="marca_txt"
      [(ngModel)]="veiculo.modelo.marca.id"
      value=""
      okText="Selecionar"
      cancelText="Cancelar"
    >
      <ion-select-option [value]="marca.id" *ngFor="let marca of marcas">
        {{ marca.marca }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label>Modelo</ion-label>
    <ion-select
      (ionChange)="modeloTxt()"
      [selectedText]="modelo_txt"
      [(ngModel)]="veiculo.modelo.id"
      value=""
      okText="Selecionar"
      cancelText="Cancelar"
    >
      <ion-select-option [value]="modelo.id" *ngFor="let modelo of modelos"
        >{{ modelo.modelo }}</ion-select-option
      >
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label>Cor</ion-label>
    <ion-select
      (ionChange)="corTxt()"
      [selectedText]="cor_txt"
      [(ngModel)]="veiculo.cor.id"
      value=""
      okText="Selecionar"
      cancelText="Cancelar"
    >
      <ion-select-option [value]="cor.id" *ngFor="let cor of cores"
        >{{ cor.cor }}</ion-select-option
      >
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Placa</ion-label>
    <ion-input
      name="placa"
      type="text"
      [(ngModel)]="veiculo.placa"
      (keyup)="placaChange($event)"
      maxlength="8"
      required
    ></ion-input>
  </ion-item>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="success" (click)="update()">
      <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
