import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Morador } from 'src/app/entities/morador';
import { MinhacontaService } from 'src/app/services/minhaconta.service';
import * as moment from "moment"

@Component({
  selector: 'app-familiar-create',
  templateUrl: './familiar-create.page.html',
  styleUrls: ['./familiar-create.page.scss'],
})
export class FamiliarCreatePage implements OnInit {
  morador: any = {
    new_foto: '',
    nome: ''
  }
  userPicture: String = "assets/img/avatarDefault.png";
  parentescos: any = [];
  loader;
  maiorDeIdade: boolean = true;
  constructor(
    private alertController: AlertController,
    private minhacontaService: MinhacontaService,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loadParentescos();
  }

  async loadParentescos(){

    this.minhacontaService.parentescos().then((response) => {
      this.parentescos = response;
    })
  }
  async responseAvatar(picture) {
    this.morador.new_foto = picture.base64;
    this.userPicture = picture.base64;
  }
  verificaMenor(idade){
    const momentDate = moment(idade).format("YYYY-MM-DD")
    const momentNow = moment()
    if (momentNow.diff(momentDate, 'year', true) < 18) {
      return true  
    }
    return false
  }

  autoriza_menor_idade(event){
   const testeIdade = this.verificaMenor(event.target.value)
    if (testeIdade) {
      this.maiorDeIdade = false
    }
    else{
      this.maiorDeIdade = true
      this.morador.autoriza_menor_idade = false
    }
  }

  async save() {
    const testeIdade = this.verificaMenor(this.morador.nascimento)
    if (testeIdade) {
      if(!this.morador.autoriza_menor_idade) {
        const alert = await this.alertController.create({
          header: "Erro",
          message:
            "É necessário confirmar o compartilhamento dos dados do menor para prosseguir com o cadastro",
          buttons: ["OK"],
      });
        alert.present();
        return; 
      }
    }

    this.loader = await this.loadingController.create({
      message: "Cadastrando familiar...",
    });
    await this.loader.present();
    this.morador.usar_control_condo = this.morador.usar_control_condo ? 1 : 0;
    await this.minhacontaService
      .saveFamiliar(this.morador)
      .then((response) => {
        
        setTimeout(() => {
          this.loader.dismiss();
          this.modalController.dismiss({ status: 'account_updated'});
        }, 300)
      })
      .catch(async (errors) => {
        this.loader.dismiss();
        
        let errorStr = "";
        for (var key in errors) {
          errorStr += `<p>` + errors[key] + `</p>`;
        }

        const alert = await this.alertController.create({
          header: "Ops",
          message: errorStr,
          buttons: ["OK"],
        });
        alert.present();
      });

    this.loader.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
