import { MinhacontaService } from './services/minhaconta.service';
import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { Device } from "@ionic-native/device/ngx";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { PipesModule } from "./pipes/pipes.module";
import { ActionSheet } from "@ionic-native/action-sheet/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import {
  SocialLoginModule,
  SocialAuthServiceConfig
} from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { MomentModule } from 'ngx-moment';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { NgxCopyToClipboardModule } from 'ngx-copy-to-clipboard';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CondominioTrocaPageModule } from './modals/condominio-troca/condominio-troca.module';
import { MeuPerfilPageModule } from './pages/morador/meu-perfil/meu-perfil.module';
import { NgCalendarModule  } from 'ionic2-calendar';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { NotificationPageModule } from './pages/common/notification/notification.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {NgxWigModule} from 'ngx-wig';
import { File } from '@ionic-native/file/ngx';
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { CallNumber } from '@ionic-native/call-number/ngx';
import { environment as env } from 'whitelabel/environments/environment';
import { HTTP } from '@ionic-native/http/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { IonicSelectableModule } from 'ionic-selectable';

// a
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DpDatePickerModule} from 'ng2-date-picker';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
registerLocaleData(localePt);

window.addEventListener('ionAlertDidPresent', e => {
  const selected = (e.target as HTMLElement).querySelector('[aria-checked="true"]');
  selected && selected.scrollIntoView();
});

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    NgxWigModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    PdfViewerModule,
    MomentModule,
    PipesModule,
    NgCalendarModule,
    NgxCopyToClipboardModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CondominioTrocaPageModule,
    MeuPerfilPageModule,
    NotificationPageModule,
    IonicSelectableModule,
    NgxMaterialTimepickerModule,
    DpDatePickerModule,

  ],
  providers: [
    StatusBar,
    Device,
    QRScanner,
    File,
    WebView,
    SignInWithApple,
    CallNumber,
    Screenshot,
    AppVersion,
    Base64ToGallery,
    ActionSheet,
    FilePath,
    NgxIonicImageViewerModule,
    AndroidPermissions,
    FirebaseMessaging,
    HTTP,
    SocialSharing,
    Camera,
    InAppBrowser,
    Facebook,
    StreamingMedia,
    MinhacontaService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    SplashScreen,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("599503934142909"),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
