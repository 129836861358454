import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { ApiRequestService } from "./api-request.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;

	constructor(
		private apiRequest: ApiRequestService,
		private storageService: StorageService,
		private router: Router
	) {
		let user_token = this.storageService.get("user_token");
		this.currentUserSubject = new BehaviorSubject(user_token);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public logout = async () => {
		return this.apiRequest.apiAut("auth/logout", {}, "POST").then(resp => {
            localStorage.clear();
            this.router.navigate(["/"], { replaceUrl: true });
        });
	};

	public counters = async () => {
		return this.apiRequest.apiAut("home/counters");
	};

	public login = async (params) => {
		return this.apiRequest.apiAut("auth/login", params, "POST");
	};

	public profile = async () => {
		return this.apiRequest.apiAut("auth/profile").then((response) => {
			return response.data;
		});
	};

	public validateAccountToken = async (params) => {
		return this.apiRequest.apiAut("auth/validateAccountToken", params, "POST");
	};

	public validateAccount = async (params, baseURL = null) => {
		return this.apiRequest.apiAut("auth/validateAccount", params, "POST", baseURL);
	};

	public validarConta = async (params) => {
		return this.apiRequest.apiAut("auth/validarConta", params, "POST");
	};

	public validateAccountSocial = async (params) => {
		return this.apiRequest.apiAut("auth/validateAccountSocial", params, "POST");
	};

	public facebook = async (params) => {
		return this.apiRequest.apiAut("auth/facebook2", params, "POST").catch((response) => {
			throw response.data;
		});
	};

	public apple = async (params) => {
		return this.apiRequest.apiAut("auth/apple2", params, "POST").catch((response) => {
			throw response.data;
		});
	};

	public gmail = async (params) => {
		return this.apiRequest.apiAut("auth/gmail", params, "POST");
	};

	public get currentUserValue() {
		return this.currentUserSubject.value;
	}

	public reloadMenuPermissions() {
		this.currentUserSubject.next({});
	}

	public verifyToken = async (params) => {
		return this.apiRequest
			.apiAut("auth/verifyToken", params, "POST")
			.then((response) => {
				this.storageService.set("user_token", response.data.token);
				this.currentUserSubject.next(params);
				return response;
			});
	};

	public validateToken(token) {
		return this.apiRequest
			.apiAut("auth/validateToken", { token })
			.then((response) => {
				return response.data;
			});
	}

	public getPermissions = async () => {
		return this.apiRequest.apiAut("auth/permissions", {}).then((response) => {
			return response.data;
		});
	};

	async updateToken(new_condominio_id, new_usuario_condominio_id, setTimeout = 3000) {
		const user_token = this.storageService.get("user_token");
		const usuario_condominio = this.storageService.get("usuario_condominio");

		const params = {
			token: user_token,
			usuario_condominio_id: usuario_condominio.id_usuario_condominio,
			condominio_id: usuario_condominio.id_condominio,
			new_condominio_id,
			setTimeout,
			new_usuario_condominio_id,
		};

    return this.apiRequest
      .apiAut("auth/updateToken", params, "PUT")
      .then((response) => {
        this.storageService.set('paginas_customizadas', response['data']['menus']); 
        return response.data;
      });
  }
}
