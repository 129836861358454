<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Meus Contatos</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed" pullFactor="0.5" pullMin="50" pullMax="200" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content
      pullingIcon="chevron-down-circle-outline"
      refreshingSpinner="circles" >
    </ion-refresher-content>
  </ion-refresher>

    <ion-item *ngIf="params.offset === 0 && contatos.length === 0 && !firstLoad">Nenhum contato encontrado</ion-item>
  
    <ion-item *ngFor="let contato of contatos" class="contato-item" lines="full">
      <div>
        <div>
          <ion-badge>
            {{ contato.tipo.descricao }}
          </ion-badge>
      
          <ion-label>
            {{ contato.contato }}
          </ion-label>
        </div>
  
        <div *ngIf="editContatos">
          <ion-button color="pallete2" (click)="modalEditContato(contato)">
            <ion-icon slot="icon-only" name="create-outline"></ion-icon>
          </ion-button>
          <ion-button color="pallete5" (click)="delete(contato.id)">
            <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
          </ion-button> 
        </div>
      </div>
    </ion-item>

  <div *ngIf="!contatosLoaded">
    <ion-item *ngFor="let c of contatosSkeleton">
      <ion-skeleton-text animated style="width: 27px; height: 27px" slot="start"></ion-skeleton-text>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </div>
  
  
  <ion-infinite-scroll *ngIf="canLoadMore" threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
    >
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <ion-fab style="position: fixed;" *ngIf="editContatos" vertical="bottom" horizontal="end" slot="fixed" (click)="modalCreateContato()">
    <ion-fab-button color="success">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
