import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LoaderControllerService } from "src/app/services/loader-controller.service";
import { NotificacaoBloqueiosService } from "src/app/services/notificacao-bloqueios.service";

@Component({
  selector: "app-notificacoes-config",
  templateUrl: "./notificacoes-config.page.html",
  styleUrls: ["./notificacoes-config.page.scss"],
})
export class NotificacoesConfigPage implements OnInit {
  configs: any = {
    reservas: false,
    comunicados: false,
    documentos: false,
    enquetes: false,
    ocorrencias: false,
    correspondencias: false,
    mudancas: false,
    saida_material: false
  };
  isPrefill: any = true;
  constructor(
    private loaderControllerService: LoaderControllerService,
    private modalController: ModalController,
    private notificacaoBloqueiosService: NotificacaoBloqueiosService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadPreferences();
  }

  async loadPreferences() {
    await this.loaderControllerService.present("Carregando...");
    await this.notificacaoBloqueiosService.list().then((result) => {
      result.map((item) => {
        if (item.type === "reservas") {
          this.configs.reservas = true;
        } else if (item.type === "comunicados") {
          this.configs.comunicados = true;
        } else if (item.type === "documentos") {
          this.configs.documentos = true;
        } else if (item.type === "enquetes") {
          this.configs.enquetes = true;
        } else if (item.type === "ocorrencias") {
          this.configs.ocorrencias = true;
        } else if (item.type === "correspondencias") {
          this.configs.correspondencias = true;
        } else if (item.type === "mudancas") {
          this.configs.mudancas = true;
        } else if (item.type === "saida_material") {
          this.configs.saida_material = true;
        }
      });
    });
    this.loaderControllerService.dismiss();
    this.isPrefill = false;
  }

  async changePreference($event, type: string) {
    $event.detail.checked ? this.delete(type) : this.save(type);
  }

  async save(type) {
    if (this.isPrefill) return;
    await this.notificacaoBloqueiosService.save({ type });
  }

  async delete(type) {
    if (this.isPrefill) return;
    await this.notificacaoBloqueiosService.delete({ type });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
