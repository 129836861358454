import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
import { HelpersService } from "src/app/services/helpers.service";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import {
	ActionSheet,
	ActionSheetOptions,
} from "@ionic-native/action-sheet/ngx";
import { File, FileEntry } from "@ionic-native/file/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { FacialService } from "../../services/facial.service"
import axios from "axios";
@Component({
	selector: "app-avatar-selector",
	templateUrl: "./avatar-selector.component.html",
	styleUrls: ["./avatar-selector.component.scss"],
})
export class AvatarSelectorComponent implements OnInit {
	@Output() onPictureSelected: EventEmitter<any> = new EventEmitter<any>();
	@Input() title: string = "Selecionar foto";
	@Input() color: string = "primary";
	@Input() fileTypes: any = ["jpeg", "jpg", "jfif"];
	@Input() uploadFileName: string = "file";
	@Input() autoSend: boolean = false;
	@Input() showEnviar: boolean = true;
	@Input() preview: any = "assets/img/avatar-placeholder.png";
	@Input() somenteCamera: boolean = false;
	formdata: any;
	idInputFile;
	loader;
	candSend: Boolean = false;
	constructor(
		private webview: WebView,
		private navController: NavController,
		private loadingController: LoadingController,
		private file: File,
		private camera: Camera,
		private actionSheet: ActionSheet,
		private alertController: AlertController,
		private helpersService: HelpersService,
		private facialService: FacialService
	) { }

	ngOnInit() {
		this.idInputFile = Math.random();
	}

	validateImage(item) {
		for (let i = 0; i < this.fileTypes.length; i++) {
			if (item === this.fileTypes[i]) return true;
		}
		return false;
	}

	async choosePictures() {
		if (this.helpersService.getDeviceType() !== "browser") {
			this.mobileGetPicture();
		} else {
			this.browserGetPicture();
		}
	}

	async browserGetPicture() {

			const pedirAcessoACameraETentarAbrir = async () => {
				if(this.somenteCamera == true) {

					await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
				}
					
				let element: HTMLElement = document.getElementById(
					this.idInputFile
					) as HTMLElement;
					
				element.click();
			}

		try {
			await pedirAcessoACameraETentarAbrir();
		} catch (error) {
			const alert = await this.alertController.create({
				header: "Erro ao acessar câmera",
				message: 'É preciso dar acesso à câmera.',
				buttons: [
					{
						text: 'Cancelar',
						handler: () => this.navController.back()
					},
					{
						text: 'Dar Acesso',
						handler: pedirAcessoACameraETentarAbrir
					}
				],
			});

			alert.present();
		}
	}

	async browser(fileInput: any) {
		if (fileInput.target.files && fileInput.target.files[0]) {
			let extension = fileInput.target.files[0].name.split(".").pop();
			if (this.validateImage(extension)) {
				this.previewImageBrowser(fileInput.target.files[0]);
				this.readFile(fileInput.target.files[0]);
			} else {
				const alert = await this.alertController.create({
					header: "Erro no envio",
					message: "Arquivos do tipo ." + extension + " não são permitidos.",
					buttons: ["OK"],
				});
				alert.present();
			}
		}
	}

	previewImageBrowser(file: any) {
		const reader = new FileReader();
		reader.onload = (e) => {
			this.preview = e.target.result;
		};
		reader.readAsDataURL(file);
	}

	previewImageMobile(file: any) {
		const reader = new FileReader();
		reader.onload = (e) => {
			this.preview = reader.result;
		};
		reader.readAsDataURL(file);
	}

	sendEvent() {
		if (this.formdata) {
			this.onPictureSelected.emit({
				formData: this.formdata,
				preview: this.preview,
			});
		}
	}

	readFile(file: any) {
		const reader = new FileReader();
		reader.onloadend = () => {
			const imgBlob = new Blob([reader.result], {
				type: file.type,
			});
			const formData = new FormData();
			formData.append(this.uploadFileName, imgBlob, file.name);
			this.formdata = formData;
			if (this.autoSend) {
				this.sendEvent();
			}
		};
		reader.readAsArrayBuffer(file);
	}

	async mobileGetPicture() {
		let buttonLabels = ["Câmera", "Galeria"];

		const options: ActionSheetOptions = {
			title: "Enviar imagem para o perfil",
			subtitle: "Escolher opção",
			buttonLabels: buttonLabels,
			addCancelButtonWithLabel: "Cancelar",
		};

		this.actionSheet.show(options).then((buttonIndex: number) => {
			if (buttonIndex === 1) {
				this.pictureFromCamera();
			} else if (buttonIndex === 2) {
				this.pictureFromGallery();
			}
		});
	}
	
	async pictureFromGallery() {
	    const options: CameraOptions = {
			quality: 70,
			destinationType: this.camera.DestinationType.DATA_URL,
			encodingType: this.camera.EncodingType.JPEG,
			mediaType: this.camera.MediaType.PICTURE,
			sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM,
			allowEdit: false,
			cameraDirection: this.camera.Direction.BACK,
			correctOrientation: true,
		};

		this.camera.getPicture(options).then(
			async (imageData) => {
				try{
					this.preview = "data:image/jpeg;base64," + imageData;
					console.log('====== [IMAGEM SELECIONADA]=========')
					console.log(this.preview)
					console.log('====== [IMAGEM SELECIONADA]=========')
					let imageName = "facial.jpeg";
					console.log('====== [IMAGEM SELECIONADA NAME]=========')
					console.log(imageName)
					console.log('====== [IMAGEM SELECIONADA NAME]=========')
					const base64Response = await fetch(this.preview);
					const blob = await base64Response.blob();
					const formData = new FormData();
					formData.append("file", blob, 'facial.jpeg');
					this.formdata = formData;
				}catch(e){
					console.log('====== [ERROR SELECIONADA NAME]=========')
					console.log(e)
					console.log('====== [ERROR SELECIONADA NAME]=========')
				}
			
			},
			(err) => {

			}
		);
	}
	b64toBlob(b64Data, contentType = '', sliceSize = 512) {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}
	pictureFromCamera() {
		this.camera
			.getPicture({
				quality: 70,
				destinationType: this.camera.DestinationType.DATA_URL,
				encodingType: this.camera.EncodingType.JPEG,
				mediaType: this.camera.MediaType.PICTURE,
				cameraDirection: this.camera.Direction.BACK,
				correctOrientation: true,
				targetWidth: 512,
			})
			.then(
				async (imageData) => {
					try{
						this.preview = "data:image/jpeg;base64," + imageData;
						let imageName = "facial.jpeg";
						console.log('====== [IMAGEM SELECIONADA NAME]=========')
						console.log(imageName)
						console.log('====== [IMAGEM SELECIONADA NAME]=========')
						const base64Response = await fetch(this.preview);
						const blob = await base64Response.blob();
						const formData = new FormData();
						formData.append("file", blob, 'facial.jpeg');
						this.formdata = formData;
					}catch(e){
						console.log('====== [ERROR SELECIONADA NAME]=========')
						console.log(e)
						console.log('====== [ERROR SELECIONADA NAME]=========')
					}
				},
				(err) => {

				}
			);
	}

	readFileMobile = async (file: any) => {
		this.loader = await this.loadingController.create({
			message: "Formatando imagem",
		});
		const imgBlob = new Blob([file], {
				type: this.facialService.getFileType(this.preview)
		});
		const formData = new FormData();
		formData.append("file", imgBlob, file.name);
		this.formdata = formData;
		await this.loader.dismiss();
	};
}
