<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Minha Conta</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="userBox" *ngIf="perfil.id">
    <div class="header">
      <img [src]="foto" id="coverAvatar" />

      <app-upload-button *ngIf="editFoto" title="" color="pallete1" icon="camera-outline"
        [fileTypes]="['jpeg', 'jpg', 'png', 'gif']" (onPictureSelected)="responseAvatar($event)">
      </app-upload-button>

      <div class="buttons">
        <ion-button color="pallete1" expand="block" (click)="modalMeuPerfilEdit()">
          Cadastro
        </ion-button>
        <ion-button *ngIf="this.canAddVeiculos" color="pallete6" expand="block" (click)="modalMeusVeiculos()">
          Veículos
        </ion-button>
        <ion-button color="pallete2" expand="block" (click)="modalMeusContato()">
          Contatos
        </ion-button>
        <ion-button color="tertiary" expand="block" (click)="modalNotificacoes()">
          Notificações
        </ion-button>
        <ion-button *ngIf="!perfil.acionador_facial && facial_enabled && moradorFacial" expand="block" color="pallete6"
          (click)="modalMoradorFacial(perfil)">
          Facial
        </ion-button>
        <ion-button *ngIf="perfil.acionador_facial && facial_enabled && moradorFacial" expand="block" color="pallete2"
          (click)="modalMoradorFacial(perfil)">
          Editar Facial
        </ion-button>
        <ion-button expand="block" color="pallete5"
          (click)="modalMoradorRemoverConta()">
          Excluir Conta
        </ion-button>
      </div>
    </div>
    <div class="info" *ngIf="perfil.id">
      <h2>{{ perfil.nome }}</h2>
      <div class="email">{{ perfil.email }}</div>
      <div>
        <div><strong>RG</strong>{{ perfil.RG }}</div>
        <div><strong>Parentesco</strong> {{ perfil.parentesco.descricao }} </div>
      </div>
      <div>
        <div><strong>CPF</strong> {{ perfil.telefone }}</div>
        <div><strong>Nascimento</strong> {{ perfil.data_nascimento }} </div>
      </div>
    </div>
  </div>
  <div class="familiares" *ngIf="familiaresLoaded">
    <ion-item *ngFor="let familiar of familiares" lines="full" (click)="modalFamiliarEdit(familiar)">
      <ion-avatar slot="start">
        <img src="{{ getFoto(familiar) }}">
      </ion-avatar>
      <ion-label>
        <strong>{{ familiar.nome }}</strong><br>
        {{ familiar.parentesco.descricao }}
      </ion-label>
      <ion-icon name="chevron-forward-outline" slot="end" *ngIf="canAddFamiliar"></ion-icon>
    </ion-item>
  </div>
  <div *ngIf="!perfil.id">
    <ion-item lines="full">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item lines="full">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </div>
  <div *ngIf="!familiaresLoaded">
    <ion-item *ngFor="let sk of familiaresSkeleton">
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="canAddFamiliar" (click)="modalFamiliarCreate()">
    <ion-fab-button color="success">
      <ion-icon name="person-add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>