import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingController, ModalController, AlertController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-condominio-troca',
  templateUrl: './condominio-troca.page.html',
  styleUrls: ['./condominio-troca.page.scss'],
})
export class CondominioTrocaPage implements OnInit {

  loader;
  permissions : any[] = [];
  search: any = "";
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private storageService: StorageService,
    private loadingController: LoadingController,
    private authService: AuthService,
    private alertController: AlertController
  ) { }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.reloadPermissions();
  }

  async reloadPermissions(){
    this.loader = await this.loadingController.create({
      message: 'Verificando permissões...',
    });
    await this.loader.present();

    await this.authService.getPermissions().then((response) => {
      this.permissions = response;
    }).catch((err) => {
      
    });

    this.loader.dismiss();
  }
  getRotulo(condominio){
    return condominio.rotulo_unidade.split('/');
  }
  getColor(param){
    let color = 'primary';
    if(param === 'MASTER'){
      color = 'success';
    }
    if(param === 'FUNCIONARIO'){
      color = 'secondary';
    }
    if(param === 'MORADOR'){
      color = 'tertiary';
    }
    return color;
  }
  async informarProblema(){
    const alert = await this.alertController.create({
      header: "Sucesso",
      message: "Sua solicitação foi enviada e você receberá um feeback através do seu email",
      buttons: ["OK"]
    });
    alert.present();
  }
  getPermissions(){
    let items =  this.permissions.filter(el => {
      if(el.condominio.nome.toUpperCase().includes(this.search.toUpperCase())){
        return true;
      }
      return false;
    });
    return items;
  } 
  async selectCondominio(c){
    this.dismiss();
    this.loader = await this.loadingController.create({
      message: 'Verificando permissões...',
    });
    await this.loader.present();
      this.authService.updateToken(c.id_condominio, c.id_usuario_condominio).then((response) => {
      this.storageService.set('usuario_condominio', c);
      this.router.navigate(["/home-loader"], { replaceUrl: true });
      
      setTimeout(() => {
        this.loader.dismiss();
        this.authService.reloadMenuPermissions();
      }, 300);
    }).catch((err) => {
      this.loader.dismiss();
    })

  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
