let autUrl = 'https://aut.controlcondo.com.br';
let autPort = 8883
let mqttServer = 'aut.controlcondo.com.br';

if (window.location.hostname == 'painel.staging.first.superlogica.com') {
	autUrl = 'https://staging.first.superlogica.com'
	autPort = 443
}

if (window.location.hostname == 'dev.controlcondo.com.br') {
	autUrl = 'https://fc-aws-dev.controlcondo.com.br';
	mqttServer = 'fc-aws-dev.controlcondo.com.br';
} else if(window.location.hostname == 'qa.controlcondo.com.br') {
	autUrl = 'https://fc-aws-qa.controlcondo.com.br';
	mqttServer = 'fc-aws-qa.controlcondo.com.br';
} else if(window.location.hostname == 'mescla.controlcondo.com.br') {
	autUrl = 'https://fc-aws-mescla.controlcondo.com.br';
	mqttServer = 'fc-aws-mescla.controlcondo.com.br';
}

export const environment = {
	production: true,
	autUrl: autUrl,
	autPort: autPort,
	appVersion: '3.5.0',
	appVersionNumber: 350,
	id_integrador: 1,
	facial_validator_sufix: ':5003/validator',
	oauth: {
		client: {
			id: 'controlcondo',
			secret: '65e263c1-1091-4404-ad82-2547be535927',
		}
	},
	mqtt: {
		server: mqttServer,
		protocol: 'wss',
		port: 59894,
		username: 'YR8Bq79QfwWxLPY'
	},
	editorConfig: {
		editable: true,
		minHeight: '150px',
		toolbarHiddenButtons: [
			[
				'undo',
				'redo',
				'underline',
				'strikeThrough',
				'subscript',
				'superscript',
				'justifyLeft',
				'justifyCenter',
				'justifyRight',
				'justifyFull',
				'indent',
				'outdent',
				'insertUnorderedList',
				'insertOrderedList',
				'heading',
				'fontName',
			],
			[
				'fontSize',
				'textColor',
				'backgroundColor',
				'customClasses',
				'link',
				'unlink',
				'insertImage',
				'insertVideo',
				'insertHorizontalRule',
				'removeFormat',
				'toggleEditorMode',
			],
		],
	},
};
