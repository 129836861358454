<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Trocar condomínio</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item>
    <ion-searchbar [(ngModel)]="search" placeholder="Digite o nome do condominio"></ion-searchbar>

  </ion-item>

  <ion-item button *ngFor="let c of getPermissions()" (click)="selectCondominio(c)"  class="condominio_select id_{{ c.id_condominio }}">
    <ion-label text-wrap>
      <div class="badges">
        <ion-badge [color]="getColor(c.tipo_usuario)">{{ c.tipo_usuario }}</ion-badge><br>
        <ion-badge color="pallete8" *ngIf="c.tipo_usuario === 'MORADOR' && c.morador && c.unidade && c.unidade.quadra">
          {{ getRotulo(c.condominio)[0] }}  
          {{ c.unidade.quadra.descricao }} - 
          {{ getRotulo(c.condominio)[1] }} 
         
          {{ c.unidade.descLote ? c.unidade.descLote : c.unidade.lote }}
        </ion-badge>
      </div>
      {{ c.condominio ? c.condominio.nome : 'Sem nome' }}
    </ion-label>
  </ion-item>

  <ion-item *ngIf="permissions.length === 0" lines="none" [hidden]="true">
    <ion-label text-wrap>
      <div style="text-align:center; padding: 15px 0px 10px 0px; font-weight: bold">Sem permissões válidas, clique abaixo para informar o problema.</div> <br>
      <ion-button (click)="informarProblema()" expand="block" color="primary">Solicitar suporte.</ion-button>
    </ion-label>
  </ion-item>
</ion-content>
