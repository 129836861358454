import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { MoradorComunicadoComponent } from "./morador/morador-comunicado/morador-comunicado.component";
import { AlertBlockComponent } from "./alert-block/alert-block.component";
import { MoradorEnqueteComponent } from "./morador/morador-enquete/morador-enquete.component";
import { MoradorDocumentoComponent } from "./morador/morador-documento/morador-documento.component";
import { MoradorVisitaComponent } from "./morador/morador-visita/morador-visita.component";
import { MoradorComunicadoComentariosComponent } from "./morador/morador-comunicado-comentarios/morador-comunicado-comentarios.component";
import { MoradorOcorrenciaComponent } from "./morador/morador-ocorrencia/morador-ocorrencia.component";
import { MoradorAssembleiaComponent } from "./morador/morador-assembleia/morador-assembleia.component";
import { AlertListComponent } from "./alert-list/alert-list.component";
import { AvatarSelectorComponent } from "./avatar-selector/avatar-selector.component";
import { PhotoSelectorComponent } from "./photo-selector/photo-selector.component";

@NgModule({
  declarations: [
    MoradorEnqueteComponent,
    MoradorDocumentoComponent,
    MoradorAssembleiaComponent,
    MoradorVisitaComponent,
    MoradorComunicadoComponent,
    MoradorComunicadoComentariosComponent,
    MoradorOcorrenciaComponent,
    AlertBlockComponent,
    AlertListComponent,
    AvatarSelectorComponent,
    PhotoSelectorComponent
  ],
  imports: [CommonModule, IonicModule],
  exports: [
    MoradorEnqueteComponent,
    MoradorDocumentoComponent,
    MoradorAssembleiaComponent,
    MoradorVisitaComponent,
    MoradorComunicadoComponent,
    MoradorComunicadoComentariosComponent,
    MoradorOcorrenciaComponent,
    AlertBlockComponent,
    AlertListComponent,
    AvatarSelectorComponent,
    PhotoSelectorComponent
  ],
})
export class MoradorComponentsModule {}
