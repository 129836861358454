import { Injectable } from "@angular/core";
import { ApiRequestService } from "./api-request.service";

@Injectable({
  providedIn: "root",
})
export class PaginaService {
  constructor(private apiRequest: ApiRequestService) {}

  public list = async () => {
    return this.apiRequest.apiServer("admin/paginas").then((response) => {
      return response.data;
    });
  };
  public get = async (id) => {
    return this.apiRequest.apiServer("admin/paginas/" + id).then((response) => {
      return response.data;
    });
  };
  public update = async (params) => {
    return this.apiRequest
      .apiServer("admin/paginas/" + params.id, params, "PUT")
      .then((response) => {
        return response.data;
      });
  };
  public getGrupoByPage = async (id) => {
    return this.apiRequest
      .apiServer("admin/paginas/" + id + "/grupos")
      .then((response) => {
        return response.data;
      });
  };
  public getGrupos = async () => {
    return this.apiRequest
      .apiServer("admin/paginas-grupos")
      .then((response) => {
        return response.data;
      });
  };
  public updatePaginaGrupos = async (params) => {
    return this.apiRequest
      .apiServer("admin/paginas-grupos/" + params.id, params, "PUT")
      .then((response) => {
        return response.data;
      });
  };
  public getPages = async () => {
    return this.apiRequest.apiServer("paginas-grupo").then((response) => {
      return response.data;
    });
  };
  public getRootCategories = async () => {
    return this.apiRequest.apiServer("root-categories").then((response) => {
      return response.data;
    });
  };
  public deletePaginaGrupo = async (id) => {
    return this.apiRequest
      .apiServer("admin/paginas-grupos/" + id, {}, "DELETE")
      .then((response) => {
        return response.data;
      });
  };
  public createAssociacaoGrupo = async (params) => {
    return this.apiRequest
      .apiServer("admin/paginas-grupos", params, "POST")
      .then((response) => {
        return response.data;
      });
  };
  public reOrderPages = async (params) => {
    return this.apiRequest
      .apiServer("admin/paginas/re-order", params, "PUT")
      .then((response) => {
        return response.data;
      });
  };
}
