import { Injectable } from "@angular/core";
const SECRET_KEY = "7815696ECBF1C96E6894B779456D330E";
import SecureLS from "secure-ls";
import { StorageTranslatorService } from './storage-translator.service';

const LS = new SecureLS({encodingType: 'des', encryptionSecret: SECRET_KEY});

@Injectable({
  providedIn: "root",
})
export class StorageService {
  ls;
  constructor(
    private storageTranslatorService: StorageTranslatorService,
  ) {

  }

  set(key, value) {
    const newKey = this.storageTranslatorService.translate(key);
    LS.set(newKey, value);
  }

  get(key){
    const newKey = this.storageTranslatorService.translate(key);
    return LS.get(newKey);
  }

}
